import React, { useState } from 'react'
import styled from 'styled-components'

export const PaymentInput = ({ children, label, placeholder, type, name, register, required = true }) => {
    const [isFocus, setIsFocus] = useState(false)

    return (
        <Input isActive={isFocus}>
            <div>
                <label>{label}</label>
                <input ref={register({ required })} name={name} onFocus={() => setIsFocus(true)} onBlur={() => setIsFocus(false)} placeholder={placeholder} type={type} />
            </div>
            {children}
        </Input>
    )
}

const Input = styled.div`
    display: flex;
    align-items: center;
    justify-content:  space-between;

    border: solid 1px ${({ isActive }) => isActive ? "rgba(81, 81, 81, 1)" : "rgba(213, 213, 213, 1)"};
    border-radius: 8px;
    padding: 8px 12px;

    div{
        display: flex;
        align-items: start;
        flex-direction: column;
        width: 100%;
    }

    label{
        font-size: 10px;
        font-weight: 400;
        line-height: 12.1px;
        text-align: left;
        color: rgba(81, 81, 81, 1);
        margin: 0;

    }

    input{
        border: none;
        width: 100%;
        padding: 0 !important;
        height: 22.5px !important;
        border: none !important;

        ::placeholder{
            color: rgba(161, 164, 171, 1);

            font-size: 15px;
            font-weight: 400;
            line-height: 18.15px;

        }

        :focus{
            border: none !important;
        }
    }

`