// Dependencias
import React, { useState } from 'react'
import styled from 'styled-components'
import Lockr from 'lockr';

// Componentes

import Header from '../Header'
import Body from '../Body'
import NavBar from './NavBar'
import { Row, Col } from 'react-bootstrap'
import LooviSelect from '../styles/LooviSelect'

// Imagens

import cardModelImage from '../../assets/images/modelo-cartao.png'

import credtiCardPlusImage from '../../assets/images/credit-card-plus-icon.png';

import mastercardImage from '../../assets/images/card-mastercard.png'
import visaImage from '../../assets/images/card-visa.png'
import creditCard from "../../assets/images/credit-card.svg"
import { PaymentInput } from './Payment/PaymentInput';
import iconCard from "../../assets/icons/icon-credit-card-gray.svg"
import { ModalSuccess } from './Payment/ModalSuccess';
import useForm from "react-hook-form";

export const ChangeCreditCardMobile = ({ customerCars, customerSelectedCar, handleSelectChange }) => {
    const [isModal, setIsModal] = useState(false)


    let customerCards = []
    const clientData = Lockr.get('ClientData')
    const { cardSummary, pmCard } = clientData.customerSelectedCar.contractInfo
    const { register, handleSubmit, errors, clearError, setValue } = useForm();

    if (!!cardSummary && !!pmCard) {
        customerCards = [{ label: pmCard === 'mc' ? 'Cartão de Crédito Mastercard' : pmCard === 'visa' ? 'Cartão de Crédito Visa' : 'Cartão de Crédito Amex', cardNum: `**** **** **** ${cardSummary}`, cardImage: pmCard === 'mc' ? mastercardImage : visaImage }];
    } else {
        customerCards = [{ label: pmCard, cardNum: `**** **** **** ${cardSummary}`, cardImage: credtiCardPlusImage }];
    }

    const onSubmit = async (data) => {
        console.log(data)
    }

    return (
        <Section>
            <Header background="#5A78FF">
                <NavBar background="#5A78FF" titleColor="#FFFFFF" backButton burgerWhite />
                <h3>TROCA DE CARTÃO</h3>
            </Header>
            <Container>
                <BoxSelectCar>
                    <p className="loovi-center loovi-bold">Selecione o veículo</p>
                    <LooviSelect
                        classNamePrefix="react-select"
                        value={customerSelectedCar}
                        onChange={handleSelectChange}
                        options={customerCars} />
                    <span className="loovi-spacer"></span>
                </BoxSelectCar>

                <BoxCard>
                    <Card>
                        <p>Nome</p>
                        <span>{customerCards[0].cardNum}</span>
                    </Card>
                    <div className='vector' />
                </BoxCard>

                <CardDetails onSubmit={handleSubmit(onSubmit)}>
                    <h4>Dados do cartão</h4>
                    <div>
                        <PaymentInput
                            label="Número do cartão"
                            placeholder="0000 0000 0000 0000"
                            type="number"
                            name="card_number"
                            register={register}

                        >
                            <img src={iconCard} alt='' />
                        </PaymentInput>
                        <PaymentInput
                            label="Data de expiração"
                            placeholder="MM/AA"
                            type="number"
                            name="expiration_data"
                            register={register}
                        />
                        <PaymentInput
                            label="CVV"
                            placeholder="3 dígitos"
                            type="number"
                            name="cvv"
                            register={register}
                        />
                        <PaymentInput
                            label="Nome no cartão"
                            placeholder="Digite o nome que está no cartão"
                            type="text"
                            name="card_name"
                            register={register}
                        />
                    </div>
                    <input value='Confirmar troca de cartão' type='submit' />
                </CardDetails>
            </Container>
            {isModal &&
                <ModalSuccess text="Troca de cartão feita com sucesso!" />
            }
        </Section>
    )
}

export default ChangeCreditCardMobile

const Section = styled.section`

    position: relative;

    h3{
        font-size: 14px !important;
        font-weight: 700;
        line-height: 18px;
        letter-spacing: 0.12em;
        text-align: center;
        color: white;
        margin: 0;

    }
`
const Container = styled.div`
`

const BoxSelectCar = styled.div`
    background-color: #5A78FF;
    padding-inline: 16px;
    padding-top: 32px;

    p{
        margin: 0;
        color: white;
        text-align: start;
    }
`

const BoxCard = styled.div`
    position: relative;
    background-color: #5A78FF;
    /* padding-inline: 16px; */
    height: 180px;

    display: flex;
    align-items: end;

    .vector{
        height: 50%;
        width: 100%;
        background-color: white;
    }
`

const Card = styled.div`
    width: 328px;
    height: 180px;
    background-image: url(${creditCard});

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    p{
        font-size: 15.67px;
        font-weight: 400;
        line-height: 23.5px;
        letter-spacing: 0.5px;
        text-align: left;
        margin: 0;
        color: white;
        
        position: absolute;
        top: 103px;
        left: 23.5px;

    }

    span{
        font-size: 20px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.16em;
        text-align: left;
        color: white;

        position: absolute;
        top: 134px;
        left: 23.5px;
    }
`

const CardDetails = styled.form`
    padding-inline: 16px;
    margin-top: 32px;

    h4{
        font-size: 16px !important;
        font-weight: 700 !important;
        color: rgba(81, 81, 81, 1) !important;
        line-height: 20px;
        text-align: start !important;
        letter-spacing: normal !important;
        text-transform: math-auto !important;

        margin: 0 0 24px 0 !important;

    }

    & > div{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, 1fr);
        grid-column-gap: 16px;
        grid-row-gap: 16px;

        div:first-child{
            grid-area: 1 / 1 / 2 / 3;
        }

        div:nth-child(2n){
            grid-area: 2 / 1 / 3 / 2;
        }

        div:nth-child(3n){
            grid-area: 2 / 2 / 3 / 3;
        }

        div:nth-child(4n){
            grid-area: 3 / 1 / 4 / 3;
        }

    }

    & > input{
        margin-top: 24px !important;
    }
`

export const Modal = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    
    width: 100vw;
    height: 100%;
    display: flex;
    align-items: end;
    justify-content: center;
    background-color: white;

    .container-modal{
        height: 65%;
        width: 100%;
        padding: 0 16px 32px 16px;

        display: flex;
        justify-content: space-between;
    }

    .container-modal > img{
        width: 32px;
        height: 32px;

        position: absolute;
        top: 24px;
        right: 18px;
    }

    div{
        width:300px;

        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
    }

    img{
        width: 64px;
        height: 64px;
    }

    p{
        font-size: 20px;
        font-weight: 700;
        line-height: 24.2px;
        text-align: center;
        margin: 0;

    }

    button{
        height: 48px;
        width: 100%;
        background-color: rgba(90, 120, 255, 1);
        font-weight: 700;
        color: white;
        border: none;
        border-radius: 4px;
    }
`
