// Dependencias

import React from 'react'
import styled from 'styled-components'
import Lockr from 'lockr';
import menuItens from '../../assets/data/menu-itens.json'

// Componentes

import { Container, Row, Col, ListGroup } from 'react-bootstrap'
import Header from '../Header'
import Select from 'react-select'
import Welcome from '../painel/Welcome'
import { handleChangeCar } from '../../helpers/handleChangeCar'
import { Redirect } from 'react-router'
import LoaderAnimation from '../LoaderAnimation'

// Imagens

import rightArrowImage from '../../assets/images/arrow-right.png'
import logoffImage from '../../assets/images/logoff-blue.png'
import looviIconImage from '../../assets/images/loovi-logo-icon.png'
import { authHandler } from '../../helpers/auth.js'
import { getContratos } from './services.js'
import Version from '../Version';
import { useState, useEffect } from 'react';

const MenuItens = () => {
    let itens = menuItens.itens.map((item) => {
        return (
            <LooviListGroup.Item
                key={item.id || item.route}
                action
                href={item.route}
            >
                {item.label}
            </LooviListGroup.Item>
        );
    });

    return (
        <LooviListGroup>
            {itens}
        </LooviListGroup>
    );
};

export class Dashboard extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            customerCars: [],
            customerInfo: {},
            customerSelectedCar: {},
            isLoading: false
        }
    }

    async componentDidMount() {
        let value;
        let clientData = Lockr.get('ClientData');
        let currentPage = 1;
        
        if (this.props.match.params.nCtrId) {
            if (clientData) {
                console.log(clientData);
    
                value = clientData.customerCars.filter(contracts => {
                    return contracts.contractInfo.nCtrId === this.props.match.params.nCtrId;
                });
    
                Lockr.set('ClientData', {
                    IdCustomerSelectedCar: value[0].value,
                    customerCars: clientData.customerCars,
                    customerSelectedCar: value[0],
                    customerInfo: clientData.customerInfo
                });
                clientData = Lockr.get('ClientData');
            }
        }
    
        this.setState({ isLoading: true });
    
        if (authHandler.isLogged()) {
            if (!clientData) {
                console.log('CSH 2');
                console.log('CSH clientData : ', clientData);
                console.log('CSH getContratos : ', this.props.match.params.nCtrId);
                getContratos().then(() => {
                    this.setState({
                        ...Lockr.get('ClientData'),
                        isLoading: false
                    });
                });
            } else {
                this.setState({
                    ...clientData,
                    isLoading: false
                });
            }
        }
    }
    
    handleSelectChange = selectedOption => {
        const { customerCars, customerInfo } = this.state;
        handleChangeCar({
            customerCars,
            customerInfo,
            selectedOption,
        });
    
        // Atualize o estado com o novo veículo selecionado
        this.setState({
            customerSelectedCar: selectedOption,
        });
    };
    

    logout = () => {
        authHandler.logout()
        this.props.history.push('/')
    }

    render() {
        const {
            customerCars,
            customerInfo,
            customerSelectedCar,
            isLoading
        } = this.state;

        if (!authHandler.isLogged()) {
            return <Redirect to="/" />
        }

        return (
            <Section>
                <LoaderAnimation show={isLoading} />
                <Header background="#5A78FF">
                    <figure className="loovi-icon"><img src={looviIconImage} alt="Loovi" /></figure>
                    <span className="loovi-spacer"></span>
                    <Welcome customerName={customerInfo.name} />
                    <span className="loovi-spacer" />
                    <Container>
                        <Row>
                            <Col sm={12} md={{ span: 6, offset: 3 }}>
                                <p className="loovi-color-0">Selecione o veículo</p>
                                <span className="loovi-align-left">
                                    
                                    <LooviSelect classNamePrefix="react-select"
                                        value={customerSelectedCar}
                                        onChange={this.handleSelectChange}
                                        options={customerCars} 
                                    />


                                    {/* <Pagination/> */}


                                </span>
                            </Col>
                        </Row>
                    </Container>
                    <span className="loovi-spacer" />
                </Header>
                <Container fluid>
                    <Row>
                        <Col className="loovi-full-col">
                            <LooviListGroup variant="flush">
                                <MenuItens />
                            </LooviListGroup>
                            <a href="#sair" className="menu-logoff" onClick={this.logout}>Sair</a>
                            <Version />
                        </Col>
                    </Row>
                </Container>
            </Section>
        )
    }
}

const Section = styled.section`
    position: relative;

    .loovi-full-container,
    .loovi-full-col{

        padding: 0;
    }

    .loovi-icon{

        padding-top: 30px;
    }

    h4{

        color: #FFFFFF;
        font-family: 'BrownStd-Regular';
    }

    .menu-logoff{

        color: #5A78FF;
        display: block;
        padding: 4px 0 4px 30px;
        margin-left: 30px;
        margin-top: 20px;
        font-size: 16px;
        background-image: url(${logoffImage});
        background-repeat: no-repeat;
        background-size: 23px;
        background-position: center left;
    }
`

const LooviListGroup = styled(ListGroup)`

    .list-group-item {

        color: #899299;
        padding: 20px 30px;
        font-family: "BrownStd-Regular";
        position: relative;

        &::before{
            content: '';
            width: 10px;
            height: 17px;
            background-image: url(${rightArrowImage});
            background-repeat: no-repeat;
            background-size: cover;
            position: absolute;
            top: 50%;
            margin-top: -8px;
            right: 40px;
        }

      &.active {
        background-color: white !important;
        border-bottom-color: rgba(0,0,0,.125) !important; 
      }
    }
`

const LooviSelect = styled(Select)`

    .react-select__control{

        border-color: #EFF2F7;
        border-width: 2px;

        input{

            height: 46px !important;
        }

        &--is-focused, &--menu-is-open{

            border-color: #57D9A3 !important;
            box-shadow: none !important;
        }
    }

    .react-select__indicator-separator{

        background: none;
    }

    .react-select__indicator{

        color: #979797;
    }

    .react-select__single-value, .react-select__option{

        color: #899299;
        font-size: 15px;
    }

    .react-select__option--is-selected{

        background: #57D9A3;
        color: #FFFFFF;
    }

    .react-select__value-container{

        padding-top: 0;
        padding-bottom: 0;
    }

    .react-select__value-container div{

        margin: 0 !important;
        padding: 0 !important;
    }
`

const StyledContainer = styled.div`
  font-size: 0.875rem;
  display: flex;
  color: #fff
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  
  & > *:not(:last-child) {
    margin-right: 0.5rem;
  }

  .button-loovi{
    cursor: pointer
    color: #fff;
    font-weight: bold;
    padding: 0.5rem; 
    border: none;
    background: none;
  }
`

export default Dashboard