// Dependencias

import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Lockr from 'lockr';

import LoaderAnimation from '../LoaderAnimation';

// Componentes

import { MobileView, BrowserView } from "react-device-detect";
import ChangeCreditCardMobile from './ChangeCreditCardMobile';
import ChangeCreditCardBrowser from './ChangeCreditCardBrowser';

import { authHandler } from '../../helpers/auth.js'

export const ChangeCreditCard = () => {
    const [customerCars, setCustomerCars] = useState([])
    const [customerSelectedCar, setCustomerSelectedCar] = useState({})
    const [isLoading, setIsLoading] = useState(false)


    useEffect(() => {
        setIsLoading(true)

        if (authHandler.isLogged()) {
            const getClient = Lockr.get('ClientData')
            setCustomerCars(getClient.customerCars)
            setCustomerSelectedCar(getClient.customerSelectedCar)

        }

        setIsLoading(false)
    }, [])

    const handleSelectChange = selectedOption => {
        setIsLoading(true)
        //console.log(selectedOption);
        setCustomerSelectedCar(selectedOption);

        let clientData;

        if (authHandler.isLogged()) {
            clientData = Lockr.get('ClientData');
            //console.log(clientData);

            Lockr.set('ClientData', {
                IdCustomerSelectedCar: clientData.customerSelectedCar.value,
                customerCars: clientData.customerCars,
                customerSelectedCar: selectedOption,
                customerInfo: clientData.customerInfo
            });

        }

        setIsLoading(false)
    };


    return (
        <Section>
            <LoaderAnimation show={isLoading} />
            <MobileView>
                <ChangeCreditCardMobile
                    customerCars={customerCars}
                    customerSelectedCar={customerSelectedCar}
                    handleSelectChange={handleSelectChange} />
            </MobileView>
            <BrowserView>
                <ChangeCreditCardBrowser
                    customerCars={customerCars}
                    customerSelectedCar={customerSelectedCar}
                    handleSelectChange={handleSelectChange} />
            </BrowserView>
        </Section>
    )

}

const Section = styled.section`
    position: relative;
    font-size:15px;

    h4{

        font-size: 12px;
        color: #9FA5B3;
        letter-spacing: 1.8px;
        text-transform: uppercase;
        text-align: center;
        line-height: 21px;
        margin-top: 100px;
    }

    .loovi-list-cards{

        border-top: solid 1px #cdd1dd;
        margin-top: 30px;

        img{

            width: 65px;
            height: auto;
            margin-right: 20px;
        }

        &__item{

            padding: 12px 20px;
            border-bottom: solid 1px #cdd1dd;
            height: 60px;
        }
    }

    .loovi-link{

        display: inline-block;
        margin: 20px 0 0 25px;
    }

    .loovi-info-data{

        p{

            margin: 0;
        }
    }

    .loovi-payment-config{

        background: #EFF2F7;
    }

    .loovi-align-left{

        text-align: left !important;
    }

    .loovi-card{

        img{

            max-width: 100% !important;
            margin: 0 0 -150px 0;
        }
    }

    .loovi-radio{

        opacity: 0.9;
        font-size: 14px;
        color: #899299;
        letter-spacing: -0.5px;
        line-height: 26px;
    }

    .checkmark{

        background: #EFF2F7 !important;
    }
`
