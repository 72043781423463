import React from 'react'

import iconCheck from "../../../../assets/icons/icon-circle-check-filled_blue.svg"
import iconClose from "../../../../assets/icons/icon-close.svg"
import styled from 'styled-components'

export const ModalSuccess = ({ text, onClose }) => {
    return <Modal>
        <div className='container-modal'>
            <img src={iconClose} onClick={onClose} />

            <div>
                <img src={iconCheck} alt='' />
                <p>{text}</p>
            </div>

            <button onClick={onClose}>Fechar</button>
        </div>
    </Modal>
}

export const Modal = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    
    width: 100vw;
    height: 100%;
    display: flex;
    align-items: end;
    justify-content: center;
    background-color: white;

    .container-modal{
        height: 65%;
        width: 100%;
        padding: 0 16px 32px 16px;

        display: flex;
        justify-content: space-between;
    }

    .container-modal > img{
        width: 32px;
        height: 32px;

        position: absolute;
        top: 24px;
        right: 18px;
    }

    div{
        width:300px;

        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
    }

    img{
        width: 64px;
        height: 64px;
    }

    p{
        font-size: 20px !important;
        font-weight: 700;
        line-height: 24.2px;
        text-align: center;
        margin: 0;

    }

    button{
        height: 48px;
        width: 100%;
        background-color: rgba(90, 120, 255, 1);
        font-weight: 700;
        color: white;
        border: none;
        border-radius: 4px;
    }
`