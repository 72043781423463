// Dependencias

import React from 'react'
import styled from 'styled-components'
import Lockr from 'lockr';

// Componentes

import Header from '../Header'
import NavBar from './NavBar'
import { MobileView, BrowserView } from "react-device-detect";
import { authHandler } from '../../helpers/auth.js'
import { RegisterMobile } from './RegisterMobile'
import { RegisterBrowser } from './RegisterBrowser'

export class Register extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            customerInfo: {},
        }
    }

    async componentDidMount() {
        if (authHandler.isLogged()) {
            this.setState(Lockr.get('ClientData'));
        }
    }

    render() {
        const { customerInfo } = this.state

        return (
            <Section>
                <Header>
                    <NavBar backButton blueArrow />
                    <MobileView>
                        <h3 className="loovi-header-title loovi-color-4">MEU CADASTRO</h3>
                    </MobileView>
                </Header>
                <MobileView>
                    <RegisterMobile data={customerInfo} />
                </MobileView>
                <BrowserView>
                    <RegisterBrowser data={customerInfo} />
                </BrowserView>
            </Section>
        )
    }
}

const Section = styled.section`
    position: relative;
    font-size:15px;

    .loovi-info-data{

        p{

            margin: 0;
        }
    }

    .loovi-header-title{
        font-size: 14px !important;
        font-weight: 700;
        line-height: 18px;
        letter-spacing: 0.12em;
        text-align: center;
        color: rgba(90, 120, 255, 1);

        margin-block: 32px;

    }

`
