import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import useForm from "react-hook-form";
import * as masks from "../../../helpers/masks";
import Lockr from "lockr";
import { FireWebToCase, GetFireWebToCase } from "../../../services/SalesForceService";
import CorreiosService from "../../../services/CorreiosService";
import check_blue from '../../../assets/icons/check_blue.svg'
import document_img from '../../../assets/icons/solicitacoes/documento.png'
import photo_icon from "../../../assets/icons/solicitacoes/photo.png";
import folder_icon from "../../../assets/icons/solicitacoes/folder.png";
import documento_crlv from '../../../assets/icons/solicitacoes/documento_crlv.png'
import { PutAuxiliaryDocuments } from "../../../services/AuxiliaryDocuments";
import { delay } from "../../../helpers/timeout";
import documento from '../../../assets/icons/solicitacoes/documentoCheck.png'
import camera_icon from "../../../assets/icons/solicitacoes/camera.png";
import Webcam from "react-webcam";

const ENVIRONMENT = process.env.REACT_APP_BASE_ENVIRONMENT;

const CASE_JSON = {
  production: "00NN5000001uaoF",
  staging: "00N880000013SdV",
  development: "00N880000013SdV",
};

const States = {
  "AC": "Acre",
  "AL": "Alagoas",
  "AP": "Amapá",
  "AM": "Amazonas",
  "BA": "Bahia",
  "CE": "Ceará",
  "DF": "Distrito Federal",
  "ES": "Espírito Santo",
  "GO": "Goiás",
  "MA": "Maranhão",
  "MT": "Mato Grosso",
  "MS": "Mato Grosso do Sul",
  "MG": "Minas Gerais",
  "PA": "Pará",
  "PB": "Paraíba",
  "PR": "Paraná",
  "PE": "Pernambuco",
  "PI": "Piauí",
  "RJ": "Rio de Janeiro",
  "RN": "Rio Grande do Norte",
  "RS": "Rio Grande do Sul",
  "RO": "Rondônia",
  "RR": "Roraima",
  "SC": "Santa Catarina",
  "SP": "São Paulo",
  "SE": "Sergipe",
  "TO": "Tocantins"
}

const Form = (props) => {
  const { register, handleSubmit, errors, clearError } = useForm();
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [clientId, setClientId] = useState("");
  const [contractId, setContractId] = useState("");
  const [iscepError, setIsCepError] = useState(false)
  const [infoCep, setInfoCep] = useState({})
  const [isCepResponse, setIsCepResponse] = useState({
    isActive: false,
    neighborhood: false,
    street: false
  })

  const [addressFields, setAddressFields] = useState({
    zipCode: "",
    state: "",
    city: "",
    neighborhood: "",
    road: "",
    number: "",
    complement: "",
  });

  const [isModalFiles, setisModalFiles] = useState(false);
  const [isModalSelectFile, setisModalSelectFile] = useState(false);
  const [stepFile, setStepFile] = useState('1')
  const [acceptFile, setAcceptFile] = useState(false)

  const [file, setFile] = useState("");
  const [fileName, setFileName] = useState("");
  const [fileType, setFileType] = useState("");
  const [fileBase64, setFileBase64] = useState("");
  const [filePdfBase64, setFilePdfBase64] = useState("");

  const [file2, setFile2] = useState("");
  const [fileName2, setFileName2] = useState("");
  const [fileType2, setFileType2] = useState("");
  const [fileBase642, setFileBase642] = useState("");
  const [filePdfBase642, setFilePdfBase642] = useState("");
  let documentType

  const [isCameraFileOpen, setIsCameraFileOpen] = useState(false);
  const [isPhotoHorizontal, setIsPhotoHorizontal] = useState(false)
  const webcamRef = useRef(null);

  const videoConstraints = {
    facingMode: { exact: "environment" },
  };

  const openModalSelectFile = () => {
    setisModalSelectFile(true);
  };

  const handleSelectChangeDocument = (value, index) => {
    props.handleSelectChangeDocument({ value: value, label: value }, index);

    if (index === 0) {
      setFileName('')
      setFile('');
      setFileBase64('');
      setFileType('');
    } else {
      setFileName2('')
      setFile2('');
      setFileBase642('');
      setFileType2('');
    }

    setStepFile('1');

    setTimeout(() => {
      setisModalFiles(true)
    }, 300);
  }

  const closeModalFiles = () => {
    setisModalFiles(false);
    setTimeout(() => {
      setIsSubmitDisabled(false);
    }, 300);
  };

  const nextStepFile = () => {
    setStepFile('2');
  };

  const handleFileChange = async (e, index) => {
    try {
      const file = e.target.files[0];
      if (file) {
        const fileUrl = URL.createObjectURL(file);
        const fileExtension = file.name.split(".").pop();

        if (index === 0) {
          setFile(fileUrl);
          setFileName(file.name)
          setFileType(fileExtension);
        } else {
          setFile2(fileUrl);
          setFileName2(file.name)
          setFileType2(fileExtension);
        }

        if (fileExtension === 'pdf') {
          const reader = new FileReader();

          reader.onload = function (e) {
            const base64String = e.target.result.split(',')[1];

            if (index === 0) {
              setFileBase64(base64String);
            } else {
              setFileBase642(base64String);
            }

            const byteCharacters = atob(base64String);
            const byteNumbers = new Uint8Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const blob = new Blob([byteNumbers], { type: 'application/pdf' });
            const blobUrl = URL.createObjectURL(blob);
            setFilePdfBase64(blobUrl);

            if (index === 0) {
              setFilePdfBase64(base64String);
            } else {
              setFilePdfBase642(base64String);
            }

            props.handleFileChange(e);
          };

          reader.readAsDataURL(file);

        } else {
          const img = new Image();
          img.src = fileUrl;
          img.onload = () => {
            const canvas = document.createElement("canvas");
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0);
            const jpgDataUrl = canvas.toDataURL("image/jpeg");

            const base64String = jpgDataUrl.split(",")[1];
            let base64 = base64String.replace(/\s/g, '')
            if (index === 0) {
              setFileBase64(base64);
            } else {
              setFileBase642(base64);
            }
            props.handleFileChange(fileUrl);
          }

          img.onerror = (error) => {
            console.error("Error loading image:", error);
          };
        };

        if(index === 0){
          if (fileExtension === 'pdf' || fileExtension === 'png' || fileExtension === 'jpg' || fileExtension === 'jpeg') {
            setAcceptFile(true)
          }
        } else {
          if (fileExtension === 'pdf' || fileExtension === 'png' || fileExtension === 'jpg' || fileExtension === 'jpeg') {
            setAcceptFile(true)
          }
        }

        setTimeout(() => {
          setisModalSelectFile(false);
        }, 300);
      }
    } catch (error) {
      console.error(error.message)
    }

  };

  const openModalCamera = (event, file) => {
    event.preventDefault();
    setIsCameraFileOpen(true);

  };

  function rotateBase64Image(base64Str) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = base64Str;

      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        canvas.width = img.width;
        canvas.height = img.height;

        const centerX = img.width / 2;
        const centerY = img.height / 2;

        ctx.translate(centerX, centerY);
        ctx.rotate(360 * Math.PI / 180);
        ctx.translate(-centerX, -centerY);

        ctx.drawImage(img, 0, 0);

        const rotatedBase64 = canvas.toDataURL();

        resolve(rotatedBase64);
      };

      img.onerror = (error) => {
        reject(error);
      };
    });
  }

  const base64ToFileURL = (base64, filename) => {
    const byteString = atob(base64.split(",")[1]);
    const mimeString = base64.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], { type: mimeString });
    return URL.createObjectURL(blob);
  };

  const capture = async (event) => {
    event.preventDefault();
    setIsPhotoHorizontal(false)

    const imageSrc = webcamRef.current.getScreenshot();

    if (window.screen.orientation.type === 'landscape-primary') {

      const fileUrl = base64ToFileURL(
        imageSrc,
        "file.jpg"
      );

      setFile(await rotateBase64Image(fileUrl));

      setIsPhotoHorizontal(true)
    } else {
      const fileUrl = base64ToFileURL(
        imageSrc,
        "file.jpg"
      );
      setFile(fileUrl);
    }

    setIsCameraFileOpen(false);
    setisModalSelectFile(false);
    setAcceptFile(true)
  };

  const handleChangeDocument = async (e) => {
    try {
      setFileName('')
      setFile('');
      setFileBase64('');
      setFileType('');
      setStepFile('1');
    } catch (error) {
      console.error(error.message)
    }
  };

  const phone = props.values.contactPhone;
  const valuesJson = () => {
    let response;
    let description2;

    if (props.values.fields[0].registrationData.value === "Endereço") {
      const addressFields = `Endereço: ${props.values.fields[0].addressFields.road}, ${props.values.fields[0].addressFields.number} - ${props.values.fields[0].addressFields.neighborhood}, ${props.values.fields[0].addressFields.city} - ${props.values.fields[0].addressFields.state}, ${props.values.fields[0].addressFields.zipCode} - complemento: ${props.values.fields[0].addressFields.complement}`;
      response = {
        description: addressFields,
        cep: props.values.fields[0].addressFields.zipCode.replace("-", ""),
        estado: States[props.values.fields[0].addressFields.state],
        cidade: props.values.fields[0].addressFields.city,
        bairro: props.values.fields[0].addressFields.neighborhood,
        rua: props.values.fields[0].addressFields.road,
        numero: props.values.fields[0].addressFields.number,
        complemento: props.values.fields[0].addressFields.complement
      }
    } else if (props.values.fields[0].registrationData.value === "Telefone") {
      const newPhone = `novo telefone: ${props.values.fields[0].newPhone}`;
      response = {
        description: newPhone,
        telefone: props.values.fields[0].newPhone,
      }
    } else if (props.values.fields[0].registrationData.value === "E-mail") {
      const newEmail = `novo email: ${props.values.fields[0].newEmail}`;
      response = {
        description: newEmail,
        email: props.values.fields[0].newEmail,
      }
    } else if (
      props.values.fields[0].registrationData.value === "Utilização do veículo"
    ) {
      const vehicleUse = `Utilização do veículo: ${props.values.fields[0].vehicleUse.value}`;
      response = {
        description: vehicleUse,
        finsComerciais: props.values.fields[0].vehicleUse.value === "Fins Comerciais"
      }
    } else if (props.values.fields[0].registrationData.value === "Alteração de documentos") {
      documentType = props.values.fields[0].newDocument.value
      const anotherMotive = `Alteração de documentos: ${documentType.toLowerCase()}`;
      response = {
        description: anotherMotive,
      }
    } else if (props.values.fields[0].registrationData.value === "Outros") {
      const anotherMotive = `Outros: ${props.values.fields[0].anotherMotive}`;
      response = {
        description: anotherMotive
      }
    }

    if (props.values.showAdditionalField) {
      if (props.values.fields[1].registrationData.value === "Endereço") {
        const addressFields = `Endereço: ${props.values.fields[1].addressFields.road}, ${props.values.fields[1].addressFields.number} - ${props.values.fields[1].addressFields.neighborhood}, ${props.values.fields[1].addressFields.city} - ${props.values.fields[1].addressFields.state}, ${props.values.fields[1].addressFields.zipCode} - complemento: ${props.values.fields[1].addressFields.complement}`;
        response = {
          ...response,
          cep: props.values.fields[1].addressFields.zipCode.replace("-", ""),
          estado: States[props.values.fields[1].addressFields.state],
          cidade: props.values.fields[1].addressFields.city,
          bairro: props.values.fields[1].addressFields.neighborhood,
          rua: props.values.fields[1].addressFields.road,
          numero: props.values.fields[1].addressFields.number,
          complemento: props.values.fields[1].addressFields.complement
        }
        description2 = addressFields;

      } else if (props.values.fields[1].registrationData.value === "Telefone") {
        const newPhone = `novo telefone: ${props.values.fields[1].newPhone}`;
        response = {
          ...response,
          telefone: props.values.fields[1].newPhone,
        }
        description2 = newPhone;
      } else if (props.values.fields[1].registrationData.value === "E-mail") {
        const newEmail = `novo email: ${props.values.fields[1].newEmail}`;
        response = {
          ...response,
          email: props.values.fields[1].newEmail,
        };
        description2 = newEmail;
      } else if (
        props.values.fields[1].registrationData.value ===
        "Utilização do veículo"
      ) {
        const vehicleUse = `Utilização do veículo: ${props.values.fields[1].vehicleUse.value}`;
        description2 = vehicleUse;
        return response
      } else if (props.values.fields[1].registrationData.value === "Alteração de documentos") {
        documentType = props.values.fields[1].newDocument.value
        const anotherMotive = `Alteração de documentos: ${documentType.toLowerCase()}`;
        description2 = anotherMotive;
      } else if (props.values.fields[1].registrationData.value === "Outros") {
        const anotherMotive = `Outros: ${props.values.fields[1].anotherMotive}`;
        description2 = anotherMotive;
      }

      return { ...response, description: `${response.description}, ${description2}` };
    } else {
      return response;
    }
  };

  const onSubmit = async (data) => {
    try {
      let CaseNumber;

      const valuesDescription = valuesJson();

      const reason = "Alteração de Dados Cadastrais";
      const subject = "Alteração de Dados Cadastrais";
      const description = `Telefone para contato: ${phone}, ${valuesDescription}`;
      const payload = {
        cep: "cep" in valuesDescription && valuesDescription.cep.length > 0 ? valuesDescription.cep : null,
        estado: "estado" in valuesDescription && valuesDescription.estado.length > 0 ? valuesDescription.estado : null,
        cidade: "cidade" in valuesDescription && valuesDescription.cidade.length > 0 ? valuesDescription.cidade : null,
        bairro: "bairro" in valuesDescription && valuesDescription.bairro.length > 0 ? valuesDescription.bairro : null,
        rua: "rua" in valuesDescription && valuesDescription.rua.length > 0 ? valuesDescription.rua : null,
        numero: "numero" in valuesDescription && valuesDescription.numero.length > 0 ? valuesDescription.numero : null,
        complemento: "complemento" in valuesDescription && valuesDescription.complemento.length > 0 ? valuesDescription.complemento : null,
        telefone: "telefone" in valuesDescription && valuesDescription.telefone.length > 0 ? valuesDescription.telefone : null,
        email: "email" in valuesDescription && valuesDescription.email.length > 0 ? valuesDescription.email : null,
        finsComerciais: "finsComerciais" in valuesDescription ? (valuesDescription.finsComerciais ? "Sim" : "Não") : null,
        codigoIbge: "CodigoIbge" in infoCep ? infoCep.CodigoIbge : null
      }

      let formData = {
        reason,
        subject,
        "00N6f00000Febwz": contractId,
        "00NN5000001Lr7C": clientId,
        [CASE_JSON[ENVIRONMENT]]: JSON.stringify(payload),
        description,
      };

      delete data["registrationDataCheck-0"];

      const formDataJson = JSON.stringify(formData);

      props.values.setLoading(true);

      await FireWebToCase(JSON.parse(formDataJson));

      if (file.length > 0) {

        await delay(10000);

        const response = await GetFireWebToCase(contractId);

        const filteredResponse = response.filter(
          (item) => item.Status === "New" && item.Reason === reason
        );

        if (filteredResponse.length > 0) {
          const selectedCase = filteredResponse[0];
          CaseNumber = selectedCase.CaseNumber;

          if (fileBase64.length > 0) {
            if (fileType === 'pdf') {
              await PutAuxiliaryDocuments(
                fileBase64,
                CaseNumber,
                'alteracao-cadastral',
                documentType,
                "pdf"
              );
            } else {
              await PutAuxiliaryDocuments(
                fileBase64,
                CaseNumber,
                'alteracao-cadastral',
                documentType,
                "jpg"
              );
            }
          }

          if (fileBase642.length > 0) {
            if (fileType2 === 'pdf') {
              await PutAuxiliaryDocuments(
                fileBase642,
                CaseNumber,
                'alteracao-cadastral',
                documentType,
                "pdf"
              );
            } else {
              await PutAuxiliaryDocuments(
                fileBase642,
                CaseNumber,
                'alteracao-cadastral',
                documentType,
                "jpg"
              );
            }
          }
        }
      }
      props.values.setLoading(false);
      props.proceed();
    } catch (error) {
      console.error("Ocorreu um erro ao enviar os dados:", error);
      props.values.setLoading(false);
    }
  };

  useEffect(() => {
    async function loadData() {
      let value;
      let clientData;

      if (props.nCtrId) {
        clientData = Lockr.get("ClientData");

        value = clientData.customerCars.filter((contracts) => {
          return contracts.contractInfo.nCtrId === props.nCtrId;
        });

        Lockr.set("ClientData", {
          IdCustomerSelectedCar: value[0].value,
          customerSelectedCar: value[0],
        });

        const customerSelectedCar = clientData.customerSelectedCar;
        const contractInfo = customerSelectedCar.contractInfo;
        setClientId(contractInfo.bpName);
        setContractId(contractInfo.nCtrId);
      } else {
        clientData = Lockr.get("ClientData");
        const customerSelectedCar = clientData.customerSelectedCar;
        const contractInfo = customerSelectedCar.contractInfo;
        setClientId(contractInfo.bpName);
        setContractId(contractInfo.nCtrId);
      }
    }

    loadData();
  }, [props.nCtrId]);

  useEffect(() => {
    let areAllFieldsFilled = true;

    Object.keys(addressFields).forEach((key) => {
      if (key !== "complement" && addressFields[key].trim() === "") {
        areAllFieldsFilled = false;
      }
    });
    setIsSubmitDisabled(!areAllFieldsFilled);
  }, [addressFields]);

  useEffect(() => {
    if (addressFields.zipCode) {
      setIsCepResponse({
        isActive: false,
        neighborhood: false,
        street: false
      })
    }
  }, [addressFields.zipCode])

  const handleAddressChange = (e, index) => {
    const { name, value } = e.target;
    const maskedValue = name === 'zipCode' ? cepMask(value) : value;
    setAddressFields((prevState) => ({ ...prevState, [name]: maskedValue }));

    if (name === 'zipCode') {
      props.handleChangeAddress(e, index);

      if (iscepError) {
        setIsCepError(false)
      }

      if (isCepResponse) {

        handleAddressChange(
          {
            target: {
              name: "neighborhood",
              value: "",
            },
          },
          index
        );

        handleAddressChange(
          {
            target: {
              name: "city",
              value: "",
            },
          },
          index
        );

        handleAddressChange(
          {
            target: {
              name: "state",
              value: "",
            },
          },
          index
        );

        handleAddressChange(
          {
            target: {
              name: "road",
              value: "",
            },
          },
          index
        );
      }
    } else {
      props.handleChangeAddress({ ...e, target: { ...e.target, value: maskedValue, name: name } }, index);
    }
    setIsSubmitDisabled(false);
  };

  const load = async (zipCode, index) => {
    props.values.setLoading(true);

    try {
      const result = await CorreiosService.getAddress(zipCode.replace(/\D/g, ''));
      setInfoCep(result)

      if (result) {
        setIsCepResponse({
          isActive: true,
          neighborhood: "Bairro" in result,
          street: "Logradouro" in result
        })

        handleAddressChange(
          {
            target: {
              name: "zipCode",
              value: cepMask(zipCode),
            },
          },
          index
        );

        if ("Bairro" in result) {
          handleAddressChange(
            {
              target: {
                name: "neighborhood",
                value: result.Bairro,
              },
            },
            index
          );
        }

        if ("Municipio" in result) {
          handleAddressChange(
            {
              target: {
                name: "city",
                value: result.Municipio,
              },
            },
            index
          );
        }

        handleAddressChange(
          {
            target: {
              name: "state",
              value: result.UF,
            },
          },
          index
        );

        if ("Logradouro" in result) {

          handleAddressChange(
            {
              target: {
                name: "road",
                value: result.Logradouro,
              },
            },
            index
          );
        }


      }
      props.values.setLoading(false);
    } catch (error) {
      const errorFormat = await error.response.json()

      if (errorFormat.Erro === "correios-cep-nao-encontrado") {
        setIsCepError(true)
      }

      props.values.setLoading(false);
      console.error("Error fetching address:", error);
    }
  };

  const cepMask = (value) => {
    return value
      .replace(/\D/g, '')
      .replace(/^(\d{5})(\d)/, '$1-$2')
  }

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        {props.values.fields.map((field, index) => (
          <>
            {index === 0 && (
              <div key={index}>
                <label>
                  <span>O QUE DESEJA ALTERAR?</span>
                  <select
                    name={`registrationDataCheck`}
                    ref={register({ required: true })}
                    className="loovi-select"
                    value={field.registrationData.value}
                    onChange={(e) => {
                      setIsSubmitDisabled(true);
                      const val = e.target.value;
                      props.handleSelectChangeData(
                        { value: val, label: val },
                        index
                      );
                      if (field.registrationData.value !== "")
                        return clearError(`registrationDataCheck`);
                    }}
                  >
                    {field.registrationsData.map((area, idx) => (
                      <option key={idx} value={area.value}>
                        {area.label}
                      </option>
                    ))}
                  </select>
                  {errors[`registrationDataCheck`] && (
                    <span className="loovi-msg-error">Selecione uma opção</span>
                  )}
                </label>
                {field.registrationData.value === "Endereço" && (
                  <>
                    <h4
                      className="loovi-color-grey-2"
                      style={{ marginTop: "1rem" }}
                    >
                      Informe seu novo endereço
                    </h4>
                    <div className="address-cep">
                      <label>
                        <span>CEP</span>
                        <input
                          type="text"
                          name={`zipCode`}
                          onChange={(e) => handleAddressChange(e, index)}
                          value={addressFields.zipCode}
                          ref={register({
                            required: true,
                            minLength: 9
                          })}
                          onBlur={(e) => load(e.target.value, index)}
                          className={
                            errors[`zipCode`]
                              ? "loovi-field loovi-field-error"
                              : "loovi-field"
                          }
                          maxLength="9"
                          inputMode="numeric"
                        />
                        {errors[`zipCode`] && (
                          <span className="loovi-msg-error">CEP inválido</span>
                        )}
                        {iscepError && (
                          <span className="loovi-msg-error">CEP não encontrado</span>
                        )}
                      </label>
                      <label>
                        <span>ESTADO</span>
                        <input
                          type="text"
                          name={`state`}
                          value={addressFields.state}
                          onChange={(e) => handleAddressChange(e, index)}
                          ref={register({ required: true })}
                          className={
                            errors[`state`]
                              ? "loovi-field loovi-field-error"
                              : "loovi-field"
                          }
                          disabled={isCepResponse.isActive}
                        />
                        {errors[`state`] && (
                          <span className="loovi-msg-error">
                            Insira o estado
                          </span>
                        )}
                      </label>
                    </div>
                    <label>
                      <span>CIDADE</span>
                      <input
                        type="text"
                        name={`city`}
                        value={addressFields.city}
                        onChange={(e) => handleAddressChange(e, index)}
                        ref={register({ required: true })}
                        className={
                          errors[`city`]
                            ? "loovi-field loovi-field-error"
                            : "loovi-field"
                        }
                        disabled={isCepResponse.isActive}
                      />
                      {errors[`city`] && (
                        <span className="loovi-msg-error">Insira a cidade</span>
                      )}
                    </label>
                    <label>
                      <span>BAIRRO</span>
                      <input
                        type="text"
                        name={`neighborhood`}
                        value={addressFields.neighborhood}
                        onChange={(e) => handleAddressChange(e, index)}
                        ref={register({ required: true })}
                        className={
                          errors[`neighborhood`]
                            ? "loovi-field loovi-field-error"
                            : "loovi-field"
                        }
                        disabled={isCepResponse.neighborhood}
                      />
                      {errors[`neighborhood`] && (
                        <span className="loovi-msg-error">Insira o bairro</span>
                      )}
                    </label>
                    <label>
                      <span>RUA</span>
                      <input
                        type="text"
                        name={`road`}
                        value={addressFields.road}
                        onChange={(e) => handleAddressChange(e, index)}
                        ref={register({ required: true })}
                        className={
                          errors[`road`]
                            ? "loovi-field loovi-field-error"
                            : "loovi-field"
                        }
                        disabled={isCepResponse.street}
                      />
                      {errors[`road`] && (
                        <span className="loovi-msg-error">Insira a rua</span>
                      )}
                    </label>
                    <label>
                      <span>NÚMERO</span>
                      <input
                        type="text"
                        name={`number`}
                        onChange={(e) => handleAddressChange(e, index)}
                        ref={register({ required: true })}
                        className={
                          errors[`number`]
                            ? "loovi-field loovi-field-error"
                            : "loovi-field"
                        }
                      />
                      {errors[`number`] && (
                        <span className="loovi-msg-error">Insira o número</span>
                      )}
                    </label>
                    <label>
                      <span>COMPLEMENTO</span>
                      <input
                        type="text"
                        name={`complement`}
                        onChange={(e) => handleAddressChange(e, index)}
                        ref={register({ required: false })}
                        className={
                          errors[`complement`]
                            ? "loovi-field loovi-field-error"
                            : "loovi-field"
                        }
                      />
                      {errors[`complement`] && (
                        <span className="loovi-msg-error">
                          Insira o complemento
                        </span>
                      )}
                    </label>
                  </>
                )}
                {field.registrationData.value === "Telefone" && (
                  <>
                    <h4
                      className="loovi-color-grey-2"
                      style={{ marginTop: "1.2rem", marginBottom: "0.7rem" }}
                    >
                      Informe seu novo número
                    </h4>
                    <label>
                      <span>NÚMERO DE TELEFONE</span>
                      <input
                        type="text"
                        name="newPhone"
                        value={field.newPhone}
                        onChange={(e) => {
                          props.handleChange(e, index);
                          if (e.target.value.length > 0) {
                            setIsSubmitDisabled(false);
                          } else {
                            setIsSubmitDisabled(true);
                          }
                        }}
                        ref={register({
                          required: true,
                          validate: (input) => {
                            const value = input.replace(/[\D]/g, "");
                            return (
                              parseInt(value[2]) === 9 && value.length >= 11
                            );
                          },
                        })}
                        className={
                          errors[`newPhone`]
                            ? "loovi-field loovi-field-error"
                            : "loovi-field"
                        }
                        inputMode="numeric"
                      />
                      {errors[`newPhone`] && (
                        <span className="loovi-msg-error">Insira o número</span>
                      )}
                    </label>
                  </>
                )}
                {field.registrationData.value === "E-mail" && (
                  <>
                    <h4
                      className="loovi-color-grey-2"
                      style={{ marginTop: "1rem" }}
                    >
                      Informe seu novo e-mail
                    </h4>
                    <label>
                      <span>INFORME SEU E-MAIL</span>
                      <input
                        type="text"
                        name="newEmail"
                        value={field.newEmail}
                        onChange={(e) => {
                          props.handleChange(e, index);
                          if (e.target.value.length > 0) {
                            setIsSubmitDisabled(false);
                          } else {
                            setIsSubmitDisabled(true);
                          }
                        }}
                        ref={register({
                          required: true,
                          validate: (input) => {
                            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                            return emailPattern.test(input);
                          },
                        })}
                        className={
                          errors[`newEmail`]
                            ? "loovi-field loovi-field-error"
                            : "loovi-field"
                        }
                      />
                      {errors[`newEmail`] && (
                        <span className="loovi-msg-error">
                          Insira um e-mail válido
                        </span>
                      )}
                    </label>
                  </>
                )}
                {field.registrationData.value === "Utilização do veículo" && (
                  <>
                    <label>
                      <span style={{ marginTop: "1rem" }}>
                        utilizo o carro para:
                      </span>
                      <select
                        name={`vehicleUseCheck`}
                        ref={register({ required: true })}
                        className="loovi-select"
                        value={field.vehicleUse.value}
                        onChange={(e) => {
                          if (e.target.value.length > 0) {
                            setIsSubmitDisabled(false);
                          } else {
                            setIsSubmitDisabled(true);
                          }
                          const val = e.target.value;
                          props.handleSelectChangeVehicle(
                            { value: val, label: val },
                            index
                          );
                          if (field.vehicleUses.value !== "")
                            return clearError(`vehicleUseCheck`);
                        }}
                      >
                        {field.vehicleUses.map((area, idx) => (
                          <option key={idx} value={area.value}>
                            {area.label}
                          </option>
                        ))}
                      </select>
                      {errors[`vehicleUseCheck`] && (
                        <span className="loovi-msg-error">
                          Selecione uma opção
                        </span>
                      )}
                    </label>
                  </>
                )}
                {field.registrationData.value === "Alteração de documentos" && (
                  <>
                    <label>
                      <span style={{ marginTop: "1rem" }}>
                        Documento que será alterado
                      </span>
                      <select
                        name={`newDocumentCheck`}
                        ref={register({ required: true })}
                        className="loovi-select"
                        value={field.newDocument.value}
                        onChange={(e) => {
                          setIsSubmitDisabled(true)
                          const val = e.target.value;
                          handleSelectChangeDocument(
                            val,
                            index
                          );
                          if (field.newDocuments.value !== "")
                            return clearError(`newDocumentCheck`);
                        }}
                      >
                        {field.newDocuments.map((area, idx) => (
                          <option key={idx} value={area.value}>
                            {area.label}
                          </option>
                        ))}
                      </select>
                      {errors[`newDocumentCheck`] && (
                        <span className="loovi-msg-error">
                          Selecione uma opção
                        </span>
                      )}
                    </label>
                    {isModalFiles && (
                      <>
                        {field.newDocument.value === 'CNH' && (
                          <>
                            <div className="modal-overlay">
                              <div className="modal-content-document">
                                <h3 className="title-modal">ALTERAÇÃO DE DOCUMENTO</h3>
                                <div className="content-document">
                                  <p className="title-document">CNH aberta</p>
                                  <div className="text-and-icon">
                                    <img src={check_blue} alt="check_blue" />
                                    <p>Anexe a sua CNH para realizar a alteração do documento</p>
                                  </div>
                                  {stepFile === '1' ? (
                                    <div className="content-img-doc">
                                      <div onClick={openModalSelectFile} className="btn-file">
                                        <img src={document_img} alt="documento" />
                                      </div>
                                      {isModalSelectFile && (
                                        <div className="modal-file">
                                          <div className="modal-file-imgs">
                                            <div className="options-img" style={{ width: '100%' }}>
                                              <div className="selected-img">
                                                <div className="content-upload">
                                                  <div className="upload-file-img">
                                                    <label htmlFor="fototeca" className="upload-file-text">
                                                      <p>Fototeca</p>
                                                    </label>
                                                    <img src={photo_icon} />
                                                  </div>
                                                  <input
                                                    id="fototeca"
                                                    type="file"
                                                    onChange={(e) => handleFileChange(e, index)}
                                                    accept=".png, .jpg, .jpeg"
                                                  />
                                                </div>
                                                <div className="divider" style={{ marginTop: '0rem' }}> </div>
                                                <div className="content-upload" onClick={(e) => openModalCamera(e)}>
                                                  <div className="upload-file-img">
                                                    <label htmlFor="Tirarfoto" className="upload-file-text">
                                                      <p>Tirar foto</p>
                                                    </label>
                                                    <img src={camera_icon} />
                                                  </div>
                                                </div>
                                                <div className="divider" style={{ marginTop: '0rem' }}> </div>
                                                <div className="content-upload">
                                                  <div className="upload-file-img">
                                                    <label htmlFor="escolherArquivo" className="upload-file-text">
                                                      <p>Escolher Arquivo</p>
                                                    </label>
                                                    <img src={folder_icon} />
                                                  </div>
                                                  <input
                                                    id="escolherArquivo"
                                                    type="file"
                                                    onChange={(e) => handleFileChange(e, index)}
                                                    accept=".png, .jpg, .jpeg,.pdf"
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )}

                                      {acceptFile === true ? (<div className="btn-confirm" onClick={nextStepFile}>Continuar</div>
                                      ) : (<div className="btn-confirm" disabled>Continuar</div>
                                      )}
                                    </div>
                                  ) : (
                                    <>
                                      <div className="content-img-doc" style={{ justifyContent: 'center' }}>
                                        {fileType === 'pdf' ? (
                                          <div className="file-pdf">
                                            <ImagePhoto
                                              style={{
                                                backgroundImage: `url(${documento})`,
                                                height: "260px",
                                                backgroundSize: "contain",
                                                padding: "0px",
                                                width: '300px',
                                                backgroundPosition: "center",
                                                backgroundRepeat: 'no-repeat',
                                                maxWidth: '300px'
                                              }}
                                            ></ImagePhoto>
                                            <p>{fileName}</p>
                                            <div className="other-document" onClick={handleChangeDocument}>
                                              <p>Anexar outra CNH</p>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="file-pdf">
                                            <ImagePhoto
                                              style={{
                                                backgroundImage: `url(${file})`,
                                                height: "260px",
                                                backgroundSize: "contain",
                                                padding: "0px",
                                                width: '300px',
                                                backgroundPosition: "center",
                                                backgroundRepeat: 'no-repeat',
                                                maxWidth: '300px'
                                              }}
                                            ></ImagePhoto>
                                            <div className="other-document" onClick={handleChangeDocument}>
                                              <p>Anexar outro CNH</p>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                      <div className="btn-confirm" style={{ marginTop: '5rem' }} onClick={closeModalFiles}>Confirmar alteração</div>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                            {(isModalFiles && isCameraFileOpen) && (
                              <div className="map-modal">
                                <div className="camera-container">
                                  <Webcam
                                    audio={false}
                                    ref={webcamRef}
                                    videoConstraints={videoConstraints}
                                    screenshotFormat="image/jpg"
                                    style={{ width: "100%", height: "100%", objectFit: "cover", minWidth: "100vw", minHeight: "100vh" }}
                                  />
                                  <ButtonCapture onClick={capture} />
                                </div>
                              </div>
                            )}
                          </>
                        )}
                        {field.newDocument.value === 'CRLV' && (
                          <>
                            <div className="modal-overlay">
                              <div className="modal-content-document">
                                <h3 className="title-modal">ALTERAÇÃO DE DOCUMENTO</h3>
                                <div className="content-document">
                                  <p className="title-document">CRLV do automóvel</p>
                                  <div className="text-and-icon">
                                    <img src={check_blue} alt="check_blue" />
                                    <p>Anexe a sua CRLV para realizar a alteração do documento</p>
                                  </div>
                                  {stepFile === '1' ? (
                                    <div className="content-img-doc">
                                      <div onClick={openModalSelectFile} className="btn-file">
                                        <img src={documento_crlv} alt="documento" />
                                      </div>
                                      {isModalSelectFile && (
                                        <div className="modal-file">
                                          <div className="modal-file-imgs">
                                            <div className="options-img" style={{ width: '100%' }}>
                                              <div className="selected-img">
                                                <div className="content-upload">
                                                  <div className="upload-file-img">
                                                    <label htmlFor="fototeca" className="upload-file-text">
                                                      <p>Fototeca</p>
                                                    </label>
                                                    <img src={photo_icon} />
                                                  </div>
                                                  <input
                                                    id="fototeca"
                                                    type="file"
                                                    onChange={(e) => handleFileChange(e, index)}
                                                    accept=".png, .jpg, .jpeg"
                                                  />
                                                </div>
                                                <div className="divider" style={{ marginTop: '0rem' }}> </div>
                                                <div className="content-upload" onClick={(e) => openModalCamera(e)}>
                                                  <div className="upload-file-img">
                                                    <label htmlFor="Tirarfoto" className="upload-file-text">
                                                      <p>Tirar foto</p>
                                                    </label>
                                                    <img src={camera_icon} />
                                                  </div>
                                                </div>
                                                <div className="divider" style={{ marginTop: '0rem' }}> </div>
                                                <div className="content-upload">
                                                  <div className="upload-file-img">
                                                    <label htmlFor="escolherArquivo" className="upload-file-text">
                                                      <p>Escolher Arquivo</p>
                                                    </label>
                                                    <img src={folder_icon} />
                                                  </div>
                                                  <input
                                                    id="escolherArquivo"
                                                    type="file"
                                                    onChange={(e) => handleFileChange(e, index)}
                                                    accept=".png, .jpg, .jpeg,.pdf"
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                      {acceptFile === true ? (<div className="btn-confirm" onClick={nextStepFile}>Continuar</div>
                                      ) : (<div className="btn-confirm" disabled>Continuar</div>
                                      )}
                                    </div>
                                  ) : (
                                    <>
                                      <div className="content-img-doc" style={{ justifyContent: 'center' }}>
                                        {fileType === 'pdf' ? (
                                          <div className="file-pdf">
                                            <ImagePhoto
                                              style={{
                                                backgroundImage: `url(${documento})`,
                                                height: "260px",
                                                backgroundSize: "contain",
                                                padding: "0px",
                                                width: '300px',
                                                backgroundPosition: "center",
                                                backgroundRepeat: 'no-repeat',
                                                maxWidth: '300px'
                                              }}
                                            ></ImagePhoto>
                                            <p>{fileName}</p>
                                            <div className="other-document" onClick={handleChangeDocument}>
                                              <p>Anexar outro CRLV</p>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="file-pdf">
                                            <ImagePhoto
                                              style={{
                                                backgroundImage: `url(${file})`,
                                                height: "260px",
                                                backgroundSize: "contain",
                                                padding: "0px",
                                                width: '300px',
                                                backgroundPosition: "center",
                                                backgroundRepeat: 'no-repeat',
                                                maxWidth: '300px'
                                              }}
                                            ></ImagePhoto>
                                            <div className="other-document" onClick={handleChangeDocument}>
                                              <p>Anexar outro CRLV</p>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                      <div className="btn-confirm" style={{ marginTop: '5rem' }} onClick={closeModalFiles}>Confirmar alteração</div>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                            {(isModalFiles && isCameraFileOpen) && (
                              <div className="map-modal">
                                <div className="camera-container">
                                  <Webcam
                                    audio={false}
                                    ref={webcamRef}
                                    videoConstraints={videoConstraints}
                                    screenshotFormat="image/jpg"
                                    style={{ width: "100%", height: "100%", objectFit: "cover", minWidth: "100vw", minHeight: "100vh" }}
                                  />
                                  <ButtonCapture onClick={capture} />
                                </div>
                              </div>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
                {field.registrationData.value === "Outros" && (
                  <>
                    <label>
                      <span style={{ marginTop: "1rem" }}>
                        DESCREVA SEU CASO
                      </span>
                      <textarea
                        name="anotherMotive"
                        value={field.anotherMotive}
                        onChange={(e) => {
                          props.handleChange(e, index);
                          if (e.target.value.length > 0) {
                            setIsSubmitDisabled(false);
                          } else {
                            setIsSubmitDisabled(true);
                          }
                        }}
                        ref={register({ required: true })}
                        className={
                          errors.anotherMotive
                            ? "loovi-field loovi-field-error"
                            : "loovi-field"
                        }
                      />
                      {errors.anotherMotive && (
                        <span className="loovi-msg-error">Insira o motivo</span>
                      )}
                    </label>
                  </>
                )}
              </div>
            )}
          </>
        ))}
        <label className="checkbox">
          <input
            type="checkbox"
            checked={props.values.showAdditionalField}
            onChange={props.handleCheckboxChange}
          />
          <span>Alterar mais dados</span>
        </label>
        {props.values.showAdditionalField && (
          <>
            {props.values.fields.map((field, index) => (
              <>
                {index === 1 && (
                  <div key={index}>
                    <label>
                      <span>O QUE DESEJA ALTERAR?</span>
                      <select
                        name={`registrationDataCheck`}
                        ref={register({ required: true })}
                        className="loovi-select"
                        value={field.registrationData.value}
                        onChange={(e) => {
                          const val = e.target.value;
                          props.handleSelectChangeData(
                            { value: val, label: val },
                            index
                          );
                          if (field.registrationData.value !== "")
                            return clearError(`registrationDataCheck`);
                        }}
                      >
                        {field.registrationsData.map((area, idx) => (
                          <option key={idx} value={area.value}>
                            {area.label}
                          </option>
                        ))}
                      </select>
                      {errors[`registrationDataCheck`] && (
                        <span className="loovi-msg-error">
                          Selecione uma opção
                        </span>
                      )}
                    </label>
                    {field.registrationData.value === "Endereço" && (
                      <>
                        <h4 className="loovi-color-grey-2">
                          Informe seu novo endereço
                        </h4>
                        <div className="address-cep">
                          <label>
                            <span>CEP</span>
                            <input
                              type="text"
                              name={`zipCode`}
                              onChange={(e) =>
                                props.handleChangeAddress(e, index)
                              }
                              ref={register({
                                required: true,

                              })}
                              onBlur={(e) => load(e.target.value, index)}
                              className={
                                errors[`zipCode`]
                                  ? "loovi-field loovi-field-error"
                                  : "loovi-field"
                              }
                              maxLength="8"
                            />
                            {errors[`zipCode`] && (
                              <span className="loovi-msg-error">
                                Insira o CEP
                              </span>
                            )}
                          </label>
                          <label>
                            <span>ESTADO</span>
                            <input
                              type="text"
                              name={`state`}
                              value={field.addressFields.state}
                              onChange={(e) =>
                                props.handleChangeAddress(e, index)
                              }
                              ref={register({ required: true })}
                              className={
                                errors[`state`]
                                  ? "loovi-field loovi-field-error"
                                  : "loovi-field"
                              }
                            />
                            {errors[`state`] && (
                              <span className="loovi-msg-error">
                                Insira o estado
                              </span>
                            )}
                          </label>
                        </div>
                        <label>
                          <span>CIDADE</span>
                          <input
                            type="text"
                            name={`city`}
                            value={field.addressFields.city}
                            onChange={(e) =>
                              props.handleChangeAddress(e, index)
                            }
                            ref={register({ required: true })}
                            className={
                              errors[`city`]
                                ? "loovi-field loovi-field-error"
                                : "loovi-field"
                            }
                          />
                          {errors[`city`] && (
                            <span className="loovi-msg-error">
                              Insira a cidade
                            </span>
                          )}
                        </label>
                        <label>
                          <span>BAIRRO</span>
                          <input
                            type="text"
                            name={`neighborhood`}
                            value={field.addressFields.neighborhood}
                            onChange={(e) =>
                              props.handleChangeAddress(e, index)
                            }
                            ref={register({ required: true })}
                            className={
                              errors[`neighborhood`]
                                ? "loovi-field loovi-field-error"
                                : "loovi-field"
                            }
                          />
                          {errors[`neighborhood`] && (
                            <span className="loovi-msg-error">
                              Insira o bairro
                            </span>
                          )}
                        </label>
                        <label>
                          <span>RUA</span>
                          <input
                            type="text"
                            name={`road`}
                            value={field.addressFields.road}
                            onChange={(e) =>
                              props.handleChangeAddress(e, index)
                            }
                            ref={register({ required: true })}
                            className={
                              errors[`road`]
                                ? "loovi-field loovi-field-error"
                                : "loovi-field"
                            }
                          />
                          {errors[`road`] && (
                            <span className="loovi-msg-error">
                              Insira a rua
                            </span>
                          )}
                        </label>
                        <label>
                          <span>NÚMERO</span>
                          <input
                            type="text"
                            name={`number`}
                            onChange={(e) =>
                              props.handleChangeAddress(e, index)
                            }
                            ref={register({ required: true })}
                            className={
                              errors[`number`]
                                ? "loovi-field loovi-field-error"
                                : "loovi-field"
                            }
                          />
                          {errors[`number`] && (
                            <span className="loovi-msg-error">
                              Insira o número
                            </span>
                          )}
                        </label>
                        <label>
                          <span>COMPLEMENTO</span>
                          <input
                            type="text"
                            name={`complement`}
                            onChange={(e) =>
                              props.handleChangeAddress(e, index)
                            }
                            ref={register({ required: true })}
                            className={
                              errors[`complement`]
                                ? "loovi-field loovi-field-error"
                                : "loovi-field"
                            }
                          />
                          {errors[`complement`] && (
                            <span className="loovi-msg-error">
                              Insira o complemento
                            </span>
                          )}
                        </label>
                      </>
                    )}
                    {field.registrationData.value === "Telefone" && (
                      <>
                        <h4 className="loovi-color-grey-2">
                          Informe seu novo número
                        </h4>
                        <label>
                          <span>NÚMERO DE TELEFONE</span>
                          <input
                            type="text"
                            name="newPhone"
                            value={field.newPhone}
                            onChange={(e) => {
                              props.handleChange(e, index);
                              if (e.target.value.length > 0) {
                                setIsSubmitDisabled(false);
                              } else {
                                setIsSubmitDisabled(true);
                              }
                            }}
                            ref={register({
                              required: true,
                              validate: (input) => {
                                const value = input.replace(/[\D]/g, "");
                                return (
                                  parseInt(value[2]) === 9 && value.length >= 11
                                );
                              },
                            })}
                            className={
                              errors[`newPhone`]
                                ? "loovi-field loovi-field-error"
                                : "loovi-field"
                            }
                            inputMode="numeric"
                          />
                          {errors[`newPhone`] && (
                            <span className="loovi-msg-error">
                              Insira o número
                            </span>
                          )}
                        </label>
                      </>
                    )}
                    {field.registrationData.value === "E-mail" && (
                      <>
                        <h4 className="loovi-color-grey-2">
                          Informe seu novo e-mail
                        </h4>
                        <label>
                          <span>INFORME SEU E-MAIL</span>
                          <input
                            type="text"
                            name="newEmail"
                            value={field.newEmail}
                            onChange={(e) => props.handleChange(e, index)}
                            ref={register({
                              required: true,
                              validate: (input) => {
                                const emailPattern =
                                  /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                                return emailPattern.test(input);
                              },
                            })}
                            className={
                              errors[`newEmail`]
                                ? "loovi-field loovi-field-error"
                                : "loovi-field"
                            }
                          />
                          {errors[`newEmail`] && (
                            <span className="loovi-msg-error">
                              Insira um e-mail válido
                            </span>
                          )}
                        </label>
                      </>
                    )}
                    {field.registrationData.value ===
                      "Utilização do veículo" && (
                        <>
                          <label>
                            <span>utilizo o carro para:</span>
                            <select
                              name={`vehicleUseCheck`}
                              ref={register({ required: true })}
                              className="loovi-select"
                              value={field.vehicleUse.value}
                              onChange={(e) => {
                                const val = e.target.value;
                                props.handleSelectChangeVehicle(
                                  { value: val, label: val },
                                  index
                                );
                                if (field.vehicleUses.value !== "")
                                  return clearError(`vehicleUseCheck`);
                              }}
                            >
                              {field.vehicleUses.map((area, idx) => (
                                <option key={idx} value={area.value}>
                                  {area.label}
                                </option>
                              ))}
                            </select>
                            {errors[`vehicleUseCheck`] && (
                              <span className="loovi-msg-error">
                                Selecione uma opção
                              </span>
                            )}
                          </label>
                        </>
                      )}
                    {field.registrationData.value === "Alteração de documentos" && (
                      <>
                        <label>
                          <span style={{ marginTop: "1rem" }}>
                            Documento que será alterado
                          </span>
                          <select
                            name={`newDocumentCheck`}
                            ref={register({ required: true })}
                            className="loovi-select"
                            value={field.newDocument.value}
                            onChange={(e) => {
                              setIsSubmitDisabled(true)
                              const val = e.target.value;
                              handleSelectChangeDocument(
                                val,
                                index
                              );
                              if (field.newDocuments.value !== "")
                                return clearError(`newDocumentCheck`);
                            }}
                          >
                            {field.newDocuments.map((area, idx) => (
                              <option key={idx} value={area.value}>
                                {area.label}
                              </option>
                            ))}
                          </select>
                          {errors[`newDocumentCheck`] && (
                            <span className="loovi-msg-error">
                              Selecione uma opção
                            </span>
                          )}
                        </label>
                        {isModalFiles && (
                          <>
                            {field.newDocument.value === 'CNH' && (
                              <>
                                <div className="modal-overlay">
                                  <div className="modal-content-document">
                                    <h3 className="title-modal">ALTERAÇÃO DE DOCUMENTO</h3>
                                    <div className="content-document">
                                      <p className="title-document">CNH aberta</p>
                                      <div className="text-and-icon">
                                        <img src={check_blue} alt="check_blue" />
                                        <p>Anexe a sua CNH para realizar a alteração do documento</p>
                                      </div>
                                      {stepFile === '1' ? (
                                        <div className="content-img-doc">
                                          <div onClick={openModalSelectFile} className="btn-file">
                                            <img src={document_img} alt="documento" />
                                          </div>
                                          {isModalSelectFile && (
                                            <div className="modal-file">
                                              <div className="modal-file-imgs">
                                                <div className="options-img" style={{ width: '100%' }}>
                                                  <div className="selected-img">
                                                    <div className="content-upload">
                                                      <div className="upload-file-img">
                                                        <label htmlFor="fototeca" className="upload-file-text">
                                                          <p>Fototeca</p>
                                                        </label>
                                                        <img src={photo_icon} />
                                                      </div>
                                                      <input
                                                        id="fototeca"
                                                        type="file"
                                                        onChange={(e) => handleFileChange(e, index)}
                                                        accept=".png, .jpg, .jpeg"
                                                      />
                                                    </div>
                                                    <div className="divider" style={{ marginTop: '0rem' }}> </div>
                                                    <div className="content-upload" onClick={(e) => openModalCamera(e)}>
                                                      <div className="upload-file-img">
                                                        <label htmlFor="Tirarfoto" className="upload-file-text">
                                                          <p>Tirar foto</p>
                                                        </label>
                                                        <img src={camera_icon} />
                                                      </div>
                                                    </div>
                                                    <div className="divider" style={{ marginTop: '0rem' }}> </div>
                                                    <div className="content-upload">
                                                      <div className="upload-file-img">
                                                        <label htmlFor="escolherArquivo" className="upload-file-text">
                                                          <p>Escolher Arquivo</p>
                                                        </label>
                                                        <img src={folder_icon} />
                                                      </div>
                                                      <input
                                                        id="escolherArquivo"
                                                        type="file"
                                                        onChange={(e) => handleFileChange(e, index)}
                                                        accept=".png, .jpg, .jpeg,.pdf"
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          )}

                                          {acceptFile === true ? (<div className="btn-confirm" onClick={nextStepFile}>Continuar</div>
                                          ) : (<div className="btn-confirm" disabled>Continuar</div>
                                          )}
                                        </div>
                                      ) : (
                                        <>
                                          <div className="content-img-doc" style={{ justifyContent: 'center' }}>
                                            {fileType === 'pdf' ? (
                                              <div className="file-pdf">
                                                <ImagePhoto
                                                  style={{
                                                    backgroundImage: `url(${documento})`,
                                                    height: "260px",
                                                    backgroundSize: "contain",
                                                    padding: "0px",
                                                    width: '300px',
                                                    backgroundPosition: "center",
                                                    backgroundRepeat: 'no-repeat',
                                                    maxWidth: '300px'
                                                  }}
                                                ></ImagePhoto>
                                                <p>{fileName2}</p>
                                                <div className="other-document" onClick={handleChangeDocument}>
                                                  <p>Anexar outra CNH</p>
                                                </div>
                                              </div>
                                            ) : (
                                              <div className="file-pdf">
                                                <ImagePhoto
                                                  style={{
                                                    backgroundImage: `url(${file2})`,
                                                    height: "260px",
                                                    backgroundSize: "contain",
                                                    padding: "0px",
                                                    width: '300px',
                                                    backgroundPosition: "center",
                                                    backgroundRepeat: 'no-repeat',
                                                    maxWidth: '300px'
                                                  }}
                                                ></ImagePhoto>
                                                <div className="other-document" onClick={handleChangeDocument}>
                                                  <p>Anexar outro CNH</p>
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                          <div className="btn-confirm" style={{ marginTop: '5rem' }} onClick={closeModalFiles}>Confirmar alteração</div>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                {(isModalFiles && isCameraFileOpen) && (
                                  <div className="map-modal">
                                    <div className="camera-container">
                                      <Webcam
                                        audio={false}
                                        ref={webcamRef}
                                        videoConstraints={videoConstraints}
                                        screenshotFormat="image/jpg"
                                        style={{ width: "100%", height: "100%", objectFit: "cover", minWidth: "100vw", minHeight: "100vh" }}
                                      />
                                      <ButtonCapture onClick={capture} />
                                    </div>
                                  </div>
                                )}
                              </>
                            )}
                            {field.newDocument.value === 'CRLV' && (
                              <>
                                <div className="modal-overlay">
                                  <div className="modal-content-document">
                                    <h3 className="title-modal">ALTERAÇÃO DE DOCUMENTO</h3>
                                    <div className="content-document">
                                      <p className="title-document">CRLV do automóvel</p>
                                      <div className="text-and-icon">
                                        <img src={check_blue} alt="check_blue" />
                                        <p>Anexe a sua CRLV para realizar a alteração do documento</p>
                                      </div>
                                      {stepFile === '1' ? (
                                        <div className="content-img-doc">
                                          <div onClick={openModalSelectFile} className="btn-file">
                                            <img src={documento_crlv} alt="documento" />
                                          </div>
                                          {isModalSelectFile && (
                                            <div className="modal-file">
                                              <div className="modal-file-imgs">
                                                <div className="options-img" style={{ width: '100%' }}>
                                                  <div className="selected-img">
                                                    <div className="content-upload">
                                                      <div className="upload-file-img">
                                                        <label htmlFor="fototeca" className="upload-file-text">
                                                          <p>Fototeca</p>
                                                        </label>
                                                        <img src={photo_icon} />
                                                      </div>
                                                      <input
                                                        id="fototeca"
                                                        type="file"
                                                        onChange={(e) => handleFileChange(e, index)}
                                                        accept=".png, .jpg, .jpeg"
                                                      />
                                                    </div>
                                                    <div className="divider" style={{ marginTop: '0rem' }}> </div>
                                                    <div className="content-upload" onClick={(e) => openModalCamera(e)}>
                                                      <div className="upload-file-img">
                                                        <label htmlFor="Tirarfoto" className="upload-file-text">
                                                          <p>Tirar foto</p>
                                                        </label>
                                                        <img src={camera_icon} />
                                                      </div>
                                                    </div>
                                                    <div className="divider" style={{ marginTop: '0rem' }}> </div>
                                                    <div className="content-upload">
                                                      <div className="upload-file-img">
                                                        <label htmlFor="escolherArquivo" className="upload-file-text">
                                                          <p>Escolher Arquivo</p>
                                                        </label>
                                                        <img src={folder_icon} />
                                                      </div>
                                                      <input
                                                        id="escolherArquivo"
                                                        type="file"
                                                        onChange={(e) => handleFileChange(e, index)}
                                                        accept=".png, .jpg, .jpeg,.pdf"
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                          {acceptFile === true ? (<div className="btn-confirm" onClick={nextStepFile}>Continuar</div>
                                          ) : (<div className="btn-confirm" disabled>Continuar</div>
                                          )}
                                        </div>
                                      ) : (
                                        <>
                                          <div className="content-img-doc" style={{ justifyContent: 'center' }}>
                                            {fileType === 'pdf' ? (
                                              <div className="file-pdf">
                                                <ImagePhoto
                                                  style={{
                                                    backgroundImage: `url(${documento})`,
                                                    height: "260px",
                                                    backgroundSize: "contain",
                                                    padding: "0px",
                                                    width: '300px',
                                                    backgroundPosition: "center",
                                                    backgroundRepeat: 'no-repeat',
                                                    maxWidth: '300px'
                                                  }}
                                                ></ImagePhoto>
                                                <p>{fileName}</p>
                                                <div className="other-document" onClick={handleChangeDocument}>
                                                  <p>Anexar outro CRLV</p>
                                                </div>
                                              </div>
                                            ) : (
                                              <div className="file-pdf">
                                                <ImagePhoto
                                                  style={{
                                                    backgroundImage: `url(${file})`,
                                                    height: "260px",
                                                    backgroundSize: "contain",
                                                    padding: "0px",
                                                    width: '300px',
                                                    backgroundPosition: "center",
                                                    backgroundRepeat: 'no-repeat',
                                                    maxWidth: '300px'
                                                  }}
                                                ></ImagePhoto>
                                                <div className="other-document" onClick={handleChangeDocument}>
                                                  <p>Anexar outro CRLV</p>
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                          <div className="btn-confirm" style={{ marginTop: '5rem' }} onClick={closeModalFiles}>Confirmar alteração</div>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                {(isModalFiles && isCameraFileOpen) && (
                                  <div className="map-modal">
                                    <div className="camera-container">
                                      <Webcam
                                        audio={false}
                                        ref={webcamRef}
                                        videoConstraints={videoConstraints}
                                        screenshotFormat="image/jpg"
                                        style={{ width: "100%", height: "100%", objectFit: "cover", minWidth: "100vw", minHeight: "100vh" }}
                                      />
                                      <ButtonCapture onClick={capture} />
                                    </div>
                                  </div>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                    {field.registrationData.value === "Outros" && (
                      <>
                        <label>
                          <span>DESCREVA SEU CASO</span>
                          <textarea
                            name="anotherMotive"
                            value={field.anotherMotive}
                            onChange={(e) => props.handleChange(e, index)}
                            ref={register({ required: true })}
                            className={
                              errors.anotherMotive
                                ? "loovi-field loovi-field-error"
                                : "loovi-field"
                            }
                          />
                          {errors.anotherMotive && (
                            <span className="loovi-msg-error">
                              Insira o motivo
                            </span>
                          )}
                        </label>
                      </>
                    )}
                  </div>
                )}
              </>
            ))}
          </>
        )}
        {props.values.fields[0].registrationData.value === "Outros" ? (
          <input
            type="submit"
            value="Enviar solicitação"
            disabled={isSubmitDisabled}
          />
        ) : (
          <input
            type="submit"
            value="Confirmar alteração"
            disabled={isSubmitDisabled}
          />
        )}
      </form>
    </React.Fragment>
  );
};

export class FormUpdateData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      showAdditionalField: false,
      fields: [
        {
          registrationsData: [
            { value: "", label: "-- Nenhum --" },
            { value: "Endereço", label: "Endereço" },
            { value: "Telefone", label: "Telefone" },
            { value: "E-mail", label: "E-mail" },
            { value: "Utilização do veículo", label: "Utilização do veículo" },
            { value: "Alteração de documentos", label: "Alteração de documentos" },
            { value: "Outros", label: "Outros" },
          ],
          registrationData: { value: "", label: "-- Nenhum --" },
          addressFields: {
            zipCode: "",
            city: "",
            state: "",
            road: "",
            number: "",
            complement: "",
            neighborhood: "",
          },
          newPhone: "",
          newEmail: "",
          anotherMotive: "",
          vehicleUse: { value: "", label: "-- Nenhum --" },
          vehicleUses: [
            { value: "", label: "-- Nenhum --" },
            { value: "Fins Comerciais", label: "Fins Comerciais" },
            { value: "Veículo de Passeio", label: "Veículo de Passeio" },
          ],
          newDocument: { value: "", label: "-- Nenhum --" },
          newDocuments: [
            { value: "", label: "-- Nenhum --" },
            { value: "CNH", label: "CNH" },
            { value: "CRLV", label: "CRLV" },
          ],
          document: ""
        },
        {
          registrationsData: [
            { value: "", label: "-- Nenhum --" },
            { value: "Endereço", label: "Endereço" },
            { value: "Telefone", label: "Telefone" },
            { value: "E-mail", label: "E-mail" },
            { value: "Utilização do veículo", label: "Utilização do veículo" },
            { value: "Alteração de documentos", label: "Alteração de documentos" },
            { value: "Outros", label: "Outros" },
          ],
          registrationData: { value: "", label: "-- Nenhum --" },
          addressFields: {
            zipCode: "",
            neighborhood: "",
            city: "",
            state: "",
            road: "",
            number: "",
            complement: "",
          },
          newPhone: "",
          newEmail: "",
          anotherMotive: "",
          vehicleUse: { value: "", label: "-- Nenhum --" },
          vehicleUses: [
            { value: "", label: "-- Nenhum --" },
            { value: "Fins Comerciais", label: "Fins Comerciais" },
            { value: "Veículo de Passeio", label: "Veículo de Passeio" },
          ],
          newDocument: { value: "", label: "-- Nenhum --" },
          newDocuments: [
            { value: "", label: "-- Nenhum --" },
            { value: "CNH", label: "CNH" },
            { value: "CRLV", label: "CRLV" },
          ],
        },
      ],
    };
  }

  proceed = () => {
    this.setState({ step: 2 });
  };

  handleSelectChangeData = (selectedOption, index) => {
    this.setState((prevState) => {
      const updatedFields = [...prevState.fields];
      updatedFields[index].registrationData = selectedOption;
      return { fields: updatedFields };
    });

    if (index === 0) {
      this.setState((prevState) => {
        const updatedFields = [...prevState.fields];
        updatedFields[0].registrationData = selectedOption;

        const filteredOptions = prevState.fields[0].registrationsData.filter(
          (option) => option.value !== selectedOption.value
        );

        updatedFields[1].registrationsData = filteredOptions;

        return { fields: updatedFields };
      });
    }
  };

  handleChangeAddress = (event, index) => {
    const { name, value } = event.target;
    this.setState((prevState) => {
      const updatedFields = [...prevState.fields];
      if (name === "zipCode") {
        const clear = value.replace(/\D/g, '')
        const masked = clear.replace(/^(\d{5})(\d)/, '$1-$2')

        updatedFields[index] = {
          ...updatedFields[index],
          addressFields: {
            ...updatedFields[index].addressFields,
            [name]: masked,
          },
        };


      } else {
        updatedFields[index] = {
          ...updatedFields[index],
          addressFields: {
            ...updatedFields[index].addressFields,
            [name]: value,
          },
        };
      }
      return { fields: updatedFields };
    });
  };

  handleChange = (event, index) => {
    const { name, value } = event.target;

    this.setState((prevState) => {
      const updatedFields = [...prevState.fields];
      const fieldToUpdate = updatedFields[index];
      if (name === "newPhone") {
        let sanitizedValue = value.replace(/[\D]/g, "");
        const maskedValue = masks.phone(sanitizedValue);
        fieldToUpdate.newPhone = maskedValue;
      } else if (name === "newEmail") {
        fieldToUpdate.newEmail = value;
      } else if (name === "anotherMotive") {
        fieldToUpdate.anotherMotive = value;
      }

      return { fields: updatedFields };
    });
  };

  handleSelectChangeVehicle = (selectedOption, index) => {
    this.setState((prevState) => {
      const updatedFields = [...prevState.fields];
      updatedFields[index].vehicleUse = selectedOption;
      return { fields: updatedFields };
    });
  };

  handleSelectChangeDocument = (selectedOption, index) => {
    this.setState((prevState) => {
      const updatedFields = [...prevState.fields];
      updatedFields[index].newDocument = selectedOption;
      return { fields: updatedFields };
    });
  };

  handleCheckboxChange = () => {
    this.setState((prevState) => ({
      showAdditionalField: !prevState.showAdditionalField,
    }));
  };

  handleFileChange = (file) => {
    this.setState({ document: file });
  };

  render() {
    const { step, showAdditionalField, fields } = this.state;
    const { contactPhone, setLoading, proceed } = this.props;
    const values = {
      step,
      showAdditionalField,
      fields,
      contactPhone,
      setLoading,
    };
    return (
      <Section>
        <Form
          values={values}
          proceed={proceed}
          handleSelectChangeData={this.handleSelectChangeData}
          handleCheckboxChange={this.handleCheckboxChange}
          handleChange={this.handleChange}
          handleChangeAddress={this.handleChangeAddress}
          handleSelectChangeVehicle={this.handleSelectChangeVehicle}
          handleSelectChangeDocument={this.handleSelectChangeDocument}
          handleFileChange={this.handleFileChange}
        />
      </Section>
    );
  }
}

const Section = styled.section`
  position: relative;
  font-size: 15px;

  p {
    margin: 0;
    font-size: 16px;
    letter-spacing: -0.32px;
    line-height: 21px;
  }

  h4 {
    font-size: 15px;
    color: #899299;
    letter-spacing: -0.32px;
    text-align: center;
    line-height: 21px;
  }

  .loovi-descktop-padding {
    h3,
    h4 {
      text-align: left;
    }
  }

  .address-cep {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
  }

  .address-cep input {
    width: 96% !important;
  }

  .checkbox {
    color: #a1a4ab !important;
    display: flex;
    align-items: center;
    margin-top: 1rem;
  }

  .checkbox span {
    text-transform: none !important;
  }

  .title-modal {
    color: #A1A4AB;
    font-size: 14px;
    font-weight: 700;
    line-height: 16.94px;
    letter-spacing: 0.15em;
    text-align: left;
    margin-top: 100px
  }

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ffffff;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: baseline;
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
    z-index: 1;
  }

  .modal-content-document {
    background-color: #fff;
    padding: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column
  }

  .content-document {
  }

  .text-and-icon {
    display: flex;
    flex-direction: row;
    margin-top: 1rem;
    align-items: center;
  }

  .text-and-icon img {
    margin-right: 1rem
  }

  .text-and-icon p {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: #999999
  }

  .title-document {
    color: #5A78FF;
    font-size: 20px;
    font-weight: 700;
    line-height: 24.2px;
    text-align: left;
  }

  .content-img-doc {
    margin-top: 3.5rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .file-pdf {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .file-pdf p {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: #999999;
    margin-top: 1rem
  }

  .other-document p {
    margin-top: 0.5rem;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: #5A78FF
  }

  .content-upload {
    height: 30px;
  }

  .btn-download {
    margin-top: 1.5rem;

    width: 100%;
    height: 48px;
    padding: 6px 0px 6px 0px;
    border-radius: 4px ;
    background: #4C6BF8;
    color: #ffffff;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    text-align: center;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center
  }

  .modal-file {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
  }

  .modal-file-imgs {
    background-color: #fff;
    padding: 14px;
    border-radius: 12px;
    width: 230px;
    height: 140px;
    position: absolute;
    justify-content: center;
    display: flex;
    align-items: center;
    box-shadow: 0px 0px 32px 0px #00000033;
    backdrop-filter: blur(150px);
    margin-top: 9rem;
  }

  .selected-img {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .upload-file-img {
    width: 100%;
    display: flex;
    flex-direction: row;
  }

  .upload-file-text {
    width: 80%;
    display: flex;
    flex-direction: row;
  }

  .upload-file-img {
    justify-content: space-between;
    z-index: 3;
    position: absolute;
    width: 200px;
  }

  .upload-file-img p {
    color: #000000;
    font-size: 17px;
    font-weight: 400;
    line-height: 22px;
    margin-left: 0.5rem
  }

  .upload-file-img img {
    width: 20px;
    height: 20px
  }

  .divider {
    width: 100%;
    height: 1px;
    background: #E5E5E5;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    z-index: 4;
    position: relative;
  }

  input[type="file" i] {
    background-color: transparent;
    color: transparent;
  }

  input::file-selector-button {
    background: transparent;
    color: transparent;
    border: none;
    z-index: 5;
    position: relative;
  }

  .btn-confirm {
    width: 100%;
    height: 48px;
    padding: 6px 0px 6px 0px;
    border-radius: 4px ;
    background: #4C6BF8;
    color: #ffffff;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    text-align: center;
    border: none;
    margin-top: 10rem;
    display: flex;
    justify-content: center;
    align-items: center
  }

  .btn-file {
    border: none;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .input-file {
    width: 100%;
    height: auto
  }

  .map-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    z-index: 4;
  }

  .camera-container {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 80%;
    height: auto;
    max-width: 800px;
    padding: 0;
  }

  @media screen and (max-width: 768px) {
    .camera-container {
      background-color: #fff;
        padding: 20px;
        border-radius: 0;
        box-shadow: none;
        width: 100%;
        height: 100vh;
        padding: 0;
    }


  }
`;

const ImagePhoto = styled.div`
`

const ButtonCapture = styled.div`
  width: 50px;
  height: 50px;
  background-color: rgba(90, 120, 255, 1);
  border-radius: 50%;

  position: absolute;
  z-index: 100;
  bottom: 30px;
  left: 50%;
  transform: translate(-50%, -50%);

  @media (orientation: landscape) {
    top: 50%;
    transform: translateY(-50%);
    right: 30px;
    margin-left: auto;
  }

`