// Dependencias

import React from "react";
import styled from "styled-components";
import Lockr from "lockr";
import { authHandler } from "../../helpers/auth.js";

// Components

import Header from "../Header";
import NavBar from "../painel/NavBar";
import { Container, Row, Col } from "react-bootstrap";
import Select from "react-select";
import Contracts from "./Contracts";
import { BrowserView, MobileView } from "react-device-detect";
import Sidebar from "../painel/Sidebar";
import * as masks from "../../helpers/masks";

import car_garage from '../../assets/icons/plan/car-garage.svg'
import car_crane from '../../assets/icons/plan/car-crane.svg'
import car_off from '../../assets/icons/plan/car-off.svg'
import car_outline from '../../assets/icons/plan/car-outline.svg'
import check from "../../assets/icons/icon-check-green.svg"

export const Plano = (props) => {
  let contractLink, contractServiceLink, idIndicacao, services;

  if (props.customerSelectedCar && props.customerSelectedCar.contractInfo) {
    const { dflContractPDF, especificContractPDF, idIndc, salesContractItems } =
      props.customerSelectedCar.contractInfo;
    contractLink = especificContractPDF;
    contractServiceLink = dflContractPDF;
    idIndicacao = idIndc;
    services = salesContractItems;
  }

  const clientData = Lockr.get("ClientData");
  const nCtrId = clientData.customerSelectedCar.contractInfo.nCtrId;
  const chave_natural = nCtrId;
  const urlapolice = `https://pdf-clientes-loovi-lti.s3.amazonaws.com/${chave_natural}/emissao/${chave_natural}.pdf`;

  // console.log(chave_natural)

  let discount = 0;
  if (
    props.customerSelectedCar &&
    props.customerSelectedCar.contractDiscounts
  ) {
    discount = props.customerSelectedCar.contractDiscounts.reduce(
      (acc, discounts) => {
        if (!discounts.indebit) acc += 1;
        return acc;
      },
      0,
    );
    if (discount > 5) discount = 5;
  }


  const handleRedirect = () => {
    window.location.href = '/suporte?area=Alteracao-de-Plano'
  };

  const filterServices = services?.filter(service =>
    service.itemCode === "SRV_ASS24" ||
    service.itemCode === "SRV_PT_ROUBO_FURTO" ||
    service.itemCode === "SRV_CARRO_RESERVA" ||
    service.itemCode === "SRV_SERVICO_LOOVI" ||
    service.itemCode === "SRV_VIDROS"
  ).map(service => {
    let subItemsArray = []

    if (service.itemCode === "SRV_ASS24") {
      subItemsArray = [
        "Serviço via 0800.",
        "Reboque até 400km.",
        "Hospedagem para até 5 pessoas.",
        "Chaveiro.",
        "Assistência para panes elétricas e mecânicas.",
        "Auxílio na falta de combustível.",
        "Troca de Pneus em emergências.",
        "Recarga para baterias "
      ]
    }

    if (service.itemCode === "SRV_PT_ROUBO_FURTO") {
      subItemsArray = [
        "Indenização Integral Fipe até o limite contratado.",
        "100% de indenização para Uber, táxi, e entregas.",
        "70% de indenização para PCD, modificados, rebaixados ou de leilão."
      ]
    }

    if (service.itemCode === "SRV_CARRO_RESERVA") {
      subItemsArray = [
        "Período disponível de 7 dias."
      ]
    }

    if (service.itemCode === "SRV_SERVICO_LOOVI") {
      subItemsArray = [
        "Pagamento integral ou parcial para colisão e fenômenos naturais. Cobertura conforme Tabela FIPE até o limite contratado.",
        "Cobertura até R$100.000 para danos materiais e corporais a terceiros em acidentes com outros veículos.",
        "Indenização de R$10.000 por morte ou invalidez a passageiros, mais reembolso até R$3.000 para despesas hospitalares."
      ]
    }

    if (service.itemCode === "SRV_VIDROS") {
      subItemsArray = [
        "Cobertura para troca ou reparo.",
        "Incluso vidros laterais, vidro traseiro, para-brisa, retrovisores, lanternas e faróis."
      ]
    }

    return {
      itemName: service.itemName,
      subItems: subItemsArray
    }
  }
  )

  return (
    <PlanContainer>
      <h4 className="loovi-color-2">{props.customerSelectedCar.label}</h4>
      <ul>
        {
          filterServices?.map((service) => {
            return (
              <Service>
                <div>
                  {service.itemName !== 'Assinatura Mensal Loovi' && (
                    service.itemName === 'Assistência 24 horas' ? <img src={car_crane} />
                      : service.itemName === 'Carro Reserva' ? <img src={car_garage} />
                        : service.itemName === 'Vidros Completos' ? <img src={car_outline} />
                          : service.itemName === 'Furto e Roubo' || service.itemName === 'Perda Total (Furto e Roubo)' ? <img src={car_off} /> : <img src={car_garage} />
                  )}
                  <h5>{service.itemName}</h5>
                </div>

                {service.subItems != null && service.subItems.length > 0 ? (
                  <ul>
                    {service.subItems.map((subItem) => (
                      <li>
                        <img src={check} />
                        <p>{subItem}</p>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <></>
                )}
              </Service>
            )
          })
        }
      </ul>

      <Discount>
        <p>Desconto de Indicação</p>

        <div>
          <p>{discount} Indicados</p>
          <span>{discount * 10}%</span>
        </div>

        <p>Código de indicação: {idIndicacao}</p>
      </Discount>
      <Footer>
        <Button onClick={handleRedirect}>Alterar Meu Plano</Button>

        <p>
          <a
            href={contractLink}
            className="loovi-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            Contrato
          </a>
        </p>

        <p>
          <a
            href={contractServiceLink}
            className="loovi-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            Contrato de prestação de serviços
          </a>
        </p>
      </Footer>
    </PlanContainer>
  );
};

const PlanContainer = styled.div`

  ul{
    margin: 0;
    padding: 0;
  }

  & > ul {
    display: flex;
    flex-direction: column;
    gap: 25px;

    margin-block: 16px;
    padding-inline: 16px;
  }

  li{
    list-style: none;
  }

  h4{
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    padding-block: 24px 16px;

    border-bottom: 1px solid rgba(229, 229, 229, 1);

  }
`

const Service = styled.li`
  display: flex;
  flex-direction: column;
  gap: 12px;

  & > div{
    display: flex;
    align-items: center;
    gap: 10px;
  }

  p{
    margin: 0;
  }

  ul{
    margin: 0;
    padding: 0;

    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  li{
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    text-align: left;
    color: rgba(81, 81, 81, 1);

    display: flex;
    align-items: start;
    gap: 8px;

  }

  h5{
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    color: black;
    letter-spacing: normal;

    margin: 0;

  }
`

const Discount = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-inline: 16px;

  padding-block: 16px;
  border-block: 1px solid rgba(229, 229, 229, 1);

  p{
    margin: 0;
  }

  & > p:first-child{
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    color: black;

  }

  div{
    display: flex;
    align-items: center;
    justify-content: space-between;

    span{
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      color: rgba(90, 120, 255, 1);

    }
  }

  p{
    color: rgba(153, 153, 153, 1);
    font-size: 16px;
  }
`

const Button = styled.button`
  height: 48px;
  width: 100%;
  border-radius: 4px;
  border: solid 1px rgba(90, 120, 255, 1);
  background-color: white;

  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
  color: rgba(90, 120, 255, 1);

`

const Footer = styled.div`
  margin-top: 16px;
  padding-inline: 16px;

  display: flex;
  flex-direction: column;
  gap: 12px;

  p{
    margin: 0;
  }
`

export const ContratosGeral = () => {
  return (
    <div className="loovi-contratos">
      <h5>Contratos Geral:</h5>
      <p>
        <a
          href="https://contratos-clientes.s3.amazonaws.com/termos-de-uso-loovi.pdf"
          className="loovi-link"
          target="_blank"
          rel="noopener noreferrer"
        >
          Termos de Uso
        </a>
      </p>
      <p>
        <a
          href="https://contratos-clientes.s3.amazonaws.com/politica-de-privacidade-loovi.pdf"
          className="loovi-link"
          target="_blank"
          rel="noopener noreferrer"
        >
          Política de Privacidade
        </a>
      </p>
      {/*             <p><a href="/" className="loovi-link" target="_blank" rel="noopener noreferrer">Plano de Indicação</a></p> */}
    </div>
  );
};

export class Signature extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      customerCars: [],
      customerInfo: {},
      customerSelectedCar: {},
      showContract: false,
    };
  }

  async componentDidMount() {
    if (authHandler.isLogged()) {
      this.setState(Lockr.get("ClientData"));
    }
  }

  showContract = () => {
    if (this.state.showContract) {
      this.setState({ showContract: false });
    } else {
      this.setState({ showContract: true });
    }
  };

  handleSelectChange = (selectedOption) => {
    this.setState({ customerSelectedCar: selectedOption });
  };

  render() {
    const { customerCars, customerSelectedCar, showContract } = this.state;

    switch (showContract) {
      case true:
        return (
          <Contracts
            showContract={this.showContract}
            contract={customerSelectedCar}
          />
        );
      default:
        return (
          <Section>
            <Header background="#5A78FF">
              <NavBar background="none" backButton burgerWhite />
              <MobileView>
                <Row style={{ paddingInline: "16px" }}>
                  <Col sm={12} md={{ span: 6, offset: 3 }}>
                    <h3 className="loovi-header-title loovi-color-0">
                      Meu plano
                    </h3>
                    <SelectCar>
                      <p>
                        Selecione o veículo
                      </p>
                      <LooviSelect
                        classNamePrefix="react-select"
                        value={customerSelectedCar}
                        onChange={this.handleSelectChange}
                        options={customerCars}
                      />
                    </SelectCar>

                  </Col>
                </Row>

              </MobileView>
            </Header>
            <MobileView>
              <Plano
                customerSelectedCar={customerSelectedCar}
                showContract={this.showContract}
              />
            </MobileView>
            <BrowserView>
              <Container fluid>
                <Row>
                  <div className="loovi-fixed-side-column">
                    <Sidebar />
                  </div>
                  <div className="loovi-fluid-column">
                    <Col>
                      <div className="loovi-descktop-padding">
                        <Row>
                          <Col>
                            <h3 className="loovi-title">Meu Plano</h3>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={12} md={8}>
                            <Plano
                              customerSelectedCar={customerSelectedCar}
                              showContract={this.showContract}
                            />
                          </Col>
                          <Col sm={12} md={4}>
                            <ContratosGeral />
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </div>
                </Row>
              </Container>
            </BrowserView>
          </Section>
        );
    }
  }
}

const Section = styled.section`
  position: relative;

  hr {
    margin: 0;
  }

  .loovi-align-left {
    text-align: left !important;
  }

  .loovi-content {
    position: relative;

    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 130px;
      background: #5a78ff;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
    }
  }

  .loovi-padding {
    padding-left: 20px;
    padding-right: 20px;
  }

  .loovi-box {
    background: #ffffff;
    border-radius: 8px;
    font-size: 14px;
    color: #899299;
    position: relative;

    h4 {
      text-align: center;
      padding: 10px 0;
      font-size: 18px;
      margin-bottom: 0;
      border-bottom: solid 1px #d8d8d8;
    }

    p {
      margin: 2px 0;
      font-size: 16px;
    }
  }

  .loovi-box-body {
    padding: 20px;
  }

  .loovi-list {
    h5 {
      font-family: "BrownStd-Regular";
      font-size: 16px;
      margin: 0;
      letter-spacing: normal;
    }

    &__item {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .loovi-contratos {
    margin-top: 40px;

    p {
      margin: 10px 0;
    }

    h5 {
      font-family: "BrownStd-Regular";
      font-size: 15px;
      color: #899299;
      letter-spacing: -0.32px;
      line-height: 21px;
    }
  }

  .loovi-descktop-padding {
    .loovi-contratos {
      margin: 0;
    }
  }
`;

const SelectCar = styled.div`
  padding-bottom: 32px;

  p{
    color: white;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;


    margin-left: 8px;
  }
`

export const LooviSelect = styled(Select)`
  .react-select__control {
    border-color: #eff2f7;
    border-width: 2px;

    input {
      height: 46px !important;
    }

    &--is-focused,
    &--menu-is-open {
      border-color: #57d9a3 !important;
      box-shadow: none !important;
    }
  }

  .react-select__indicator-separator {
    background: none;
  }

  .react-select__indicator {
    color: #979797;
  }

  .react-select__single-value,
  .react-select__option {
    color: #899299;
    font-size: 15px;
  }

  .react-select__option--is-selected {
    background: #57d9a3;
    color: #ffffff;
  }

  .react-select__value-container {
    padding-top: 0;
    padding-bottom: 0;
  }

  .react-select__value-container div {
    margin: 0 !important;
    padding: 0 !important;
  }
`;

export default Signature;
