import React, { useEffect, useState } from "react";
import useForm from "react-hook-form";
import Lockr from "lockr";
import { Container, Row, Col } from "react-bootstrap";

import { MobileView, BrowserView } from "react-device-detect";

import Header from "../Header";
import NavBar from "./NavBar";
import Sidebar from "./Sidebar";
//import LoaderAnimation from "../LoaderAnimation";

import * as masks from "../../helpers/masks";

import styled from "styled-components";
import SapService from '../../services/SapService';
import "../../styles/style.scss";

import checkIconImage from '../../assets/images/check-parabens.png'
import iconPix from '../../assets/icons/icons-pix.svg'
import iconCard from '../../assets/icons/icon-credit-card-gray.svg'
import iconMobile from '../../assets/icons/icon-mobile.svg'
import iconCopy from '../../assets/icons/icon-copy.svg'

export default function AdvancePayment(props) {
  const [isLoading, setLoading] = useState(false);
  const [isPossibleAdvancePayment, setIsPossibleAdvancePayment] = useState(true);
  const [txtIsPossibleAdvancePayment, setTxtIsPossibleAdvancePayment] = useState('');

  const [state, setState] = useState({
    nCtrId: "",
    advancePaymentMethod: { value: "MP", label: "-- Método de Pagamento --" },
    advancePaymentContent: { chaveAleatoria: "", dataExpiracao: "", link: "", pspReference: "", mensagem: "", sucesso: false },
    advancePaymentValue: "",
  });

  useEffect(() => {
    let clientData = Lockr.get("ClientData");
    //setLoading(true);

    async function fetchClientData() {
      try {
        setState((prevstate) => ({ ...prevstate, nCtrId: clientData.customerSelectedCar.contractInfo.nCtrId }));
        const response = await SapService.getAdvancePaymentValue(clientData.customerSelectedCar.contractInfo.nCtrId);

        if (response) {
          if (response.sucesso) {
            setState((prevstate) => ({ ...prevstate, advancePaymentValue: masks.real(response.conteudo.valor) }));
          } else {
            setIsPossibleAdvancePayment(false);
            setTxtIsPossibleAdvancePayment(response.mensagem);
          }
        }
      } catch (error) {
        alert('Ops! ocorreu um erro, tente novamente mais tarde.');
      }
    }
    fetchClientData();
    //setLoading(false);
  }, []);

  const onSelectChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setState({ ...state, [name]: { value, label: value } });
  };

  const onSubmit = async (data) => {
    console.log("onSubmit : ", state.nCtrId, " / ", state.advancePaymentMethod.value);
    setLoading(true);
    const response = await SapService.getAdvancePaymentInfo(state.nCtrId, state.advancePaymentMethod.value);

    //PI 
    //const encodedString = `{"conteudo": {"dataExpiracao": "2022-11-04T18:23:15Z", "pspReference": "P57VV7DP8QHG5S82", "chaveAleatoria": "TestQRCodeEMVToken", "link": "https://test.adyen.com/hpp/generateQRCodeImage.shtml?url=TestQRCodeEMVToken"}, "sucesso": true, "mensagem": "QRCODE gerado com sucesso."}`;

    //LP
    //const encodedString = `{"conteudo": {"dataExpiracao": "2023-10-18T08:57:15Z","link": "https://test.adyen.link/PLE82FA9BFC0E362E2","pspReference": "PLE82FA9BFC0E362E2"},"sucesso": true,"mensagem": "Link gerado com sucesso."}`

    //CC
    //const encodedString = `{"conteudo": {},"sucesso": true,"mensagem": "Antecipação de pagamento solicitada com sucesso. Aguardar processamento de cobrança"}`

    //const response = JSON.parse(encodedString);

    console.log("response getAdvancePaymentInfo : ", response);
    if (response) {
      if (response.sucesso) {
        console.log(state.advancePaymentMethod.value);
        if (state.advancePaymentMethod.value === "PI") {
          setState({
            ...state, advancePaymentContent: {
              chaveAleatoria: response.conteudo.chaveAleatoria,
              dataExpiracao: response.conteudo.dataExpiracao,
              link: response.conteudo.link,
              pspReference: response.conteudo.pspReference,
              mensagem: response.mensagem,
              sucesso: response.sucesso
            }
          });
        }
        if (state.advancePaymentMethod.value === "LP") {
          window.open(response.conteudo.link, '_blank', 'noopener,noreferrer');
        }
      } else {
        if (response.erro) {
          alert(`Ops! ${response.erro}`);
        } else {
          alert(`Ops! ocorreu um erro, tente novamente mais tarde.`);
        }
      }

    } else {
      alert(`Ops! ocorreu um erro, tente novamente mais tarde.`);
    }


    //setLoading(false);
  };

  return (
    <Section>
      <Header>
        <NavBar backButton blueArrow />
      </Header>
      <MobileView style={{ height: "100%", paddingInline: "16px" }}>
        {isPossibleAdvancePayment && <Form
          state={state}
          onSelectChange={onSelectChange}
          onSubmit={onSubmit}
        />}
        {!isPossibleAdvancePayment && <div>
          <h3 className="loovi-header-title loovi-color-4">ANTECIPAÇÃO DE FATURA</h3>
          <p className="loovi-color-4">{txtIsPossibleAdvancePayment}</p>
        </div>}
      </MobileView>
      <BrowserView>
        <Container fluid>
          <Row>
            <div className="loovi-fixed-side-column">
              <Sidebar />
            </div>
            <div className="loovi-fluid-column">
              <Col sm={12} md={6}>
                <div className="loovi-descktop-padding">
                  {isPossibleAdvancePayment && <Form
                    state={state}
                    onSelectChange={onSelectChange}
                    onSubmit={onSubmit}
                  />}
                  {!isPossibleAdvancePayment && <div>
                    <h3 className="loovi-header-title loovi-color-4">ANTECIPAÇÃO DE FATURA</h3>
                    <p className="loovi-color-4">{txtIsPossibleAdvancePayment}</p>
                  </div>}
                </div>
              </Col>
            </div>
          </Row>
        </Container>
      </BrowserView>
    </Section>
  );
}

function Form({ state, onSelectChange, onSubmit }) {
  const { register, handleSubmit, errors, clearError } = useForm();
  //const [isLoading, setLoading] = useState(false)

  const paymentMethods = [
    { label: '-- Método de Pagamento --', value: 'MP', disabled: true },
    { label: 'Pix', value: 'PI', disabled: false },
    { label: 'Cartão de Crédito', value: 'CC', disabled: false },
    { label: 'Link de pagamento', value: 'LP', disabled: false }
  ];

  const handleCopy = (event) => {
    event.preventDefault()
    const tempInput = document.createElement('input');
    tempInput.value = state.advancePaymentContent.chaveAleatoria
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);
  }

  return (
    <FormStyled>
      {/* <LoaderAnimation show={isLoading} /> */}
      <h3 className="loovi-header-title loovi-color-4">ANTECIPAÇÃO DE FATURA</h3>
      <p className="loovi-color-4">Antecipe o pagamento de sua fatura de forma rápida e prática. Escolha a forma de pagamento, solicite a antecipação e aguarde a confirmação do pagamento em seu e-mail.</p>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div style={{ display: "flex", flexDirection: "column", gap: "32px" }}>
          <PaymentValue>
            <p>Valor a antecipar:</p>
            <p>{state.advancePaymentValue}</p>
          </PaymentValue>

          <PaymentMethod>
            <p>Método de pagamento</p>
            <select
              name="advancePaymentMethod"
              ref={register({ required: true })}
              className="loovi-select"
              value={state.advancePaymentMethod.value}
              onChange={(e) => {
                onSelectChange(e);
                if (state.advancePaymentMethod.value !== "") return clearError("advancePaymentMethod")
              }} >

              {paymentMethods.map((paymentMethod, index) => (
                <option key={index} value={paymentMethod.value} disabled={paymentMethod.disabled}>{paymentMethod.label}</option>
              ))}
            </select>
            {errors.advancePaymentMethod && <span className="loovi-msg-error">Selecione um método de pagamento para antecipação</span>}

          </PaymentMethod>
        </div>

        {!state.advancePaymentContent.sucesso && <BoxInput isActive={state.advancePaymentMethod.value !== "MP"}>
          {<input type="submit" value="Solicitar Antecipação" />}
        </BoxInput>}
        {(state.advancePaymentMethod.value === "PI" && state.advancePaymentContent.sucesso) &&

          <PixArea>
            <PopUp>
              <p>
                <img src={iconMobile} alt="" />
                Pagamento com Pix
              </p>
              <span>
                Copie o código Pix, acesse a seção Pix no app do seu banco e cole o código para efetuar o pagamento.
              </span>
            </PopUp>
            <div className="box">
              <img src={state.advancePaymentContent.link} alt={'QR Code PIX'} />
              <p>{state.advancePaymentContent.chaveAleatoria}</p>
            </div>

            <button onClick={handleCopy}><p>Copiar Pix</p> <img src={iconCopy} /></button>
          </PixArea>
        }

        {(state.advancePaymentMethod.value === "CC" && state.advancePaymentContent.sucesso) &&
          <div>
            <span className="loovi-spacer"></span>
            <div className="loovi-sub-header loovi-center">
              <Container>
                <h4 className="loovi-subtitle">{state.advancePaymentContent.mensagem}</h4>
                <figure className="loovi-check"><img src={checkIconImage} alt="Parabéns" /></figure>
              </Container>
            </div>
          </div>
        }

      </form>
    </FormStyled>
  );
}

const Section = styled.section`
  position: relative;
  font-size: 15px;
  height: 100vh;

  display: grid;
  grid-template-rows: 86px 1fr;

  p {
    margin: 0;
    font-size: 16px;
    letter-spacing: -0.32px;
    line-height: 21px;
  }

  h4 {
    font-size: 15px;
    color: #899299;
    letter-spacing: -0.32px;
    text-align: center;
    line-height: 21px;
  }

  .loovi-descktop-padding {
    h3,
    h4 {
      text-align: left;
    }
  }

  h4.loovi-subtitle{

    position: relative;
    font-family: 'BrownStd-Regular';
    font-size: 18px;
    color: #899299;
    letter-spacing: -0.64px;
    text-align: center;
    line-height: 26px;
}

.loovi-sub-header-browser{

    margin-bottom: 60px;
}

.loovi-fluid-column{

    position: relative;
    z-index: 0;

    &::before{

        content: '';
        display: block;
        width: 30px;
        height: 300px;
        background: #FFFFFF;
        position: absolute;
        left: 0;
        top: 0;
    }
}

h5.loovi-body-title{

    font-size: 21px;
    color: #5A78FF;
    letter-spacing: -0.75px;
    line-height: 26px;
}

p{

    font-size: 15px;
}

.loovi-check{

    img{

        width: 44px;
        height: auto;
    }
}

.loovi-image-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 30%;
}

`;

const FormStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;

  margin-inline: 16px;
  height: 100%;

  h3{
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.12em;
    text-align: center;
    color: rgba(90, 120, 255, 1);

    margin: 0;
  }

  & > p{
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: rgba(161, 164, 171, 1);

  }

  form{
    height: 100%;

    display: flex;
    flex-direction: column;
  }
`

const PaymentValue = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  p{
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    text-align: left;

  }
`

const PaymentMethod = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  p{
    font-size: 12px;
    font-weight: 700;
    line-height: 14.52px;
    letter-spacing: 0.09em;
    text-align: left;
    margin: 0;

    text-transform: uppercase;
    padding-left: 8px;
  }

  select{
    width: 100%;
    height: 50px;
    border: rgba(242, 243, 248, 1) 1px solid;
    border-radius: 4px;
    padding-inline: 10px;

    color: rgba(161, 164, 171, 1);

  }

`

const BoxInput = styled.div`
  height: 100%;
  display: flex;
  align-items: end;

  padding-bottom: 16px;

  input[type="submit"]{
    background-color: ${({ isActive }) => isActive ? "#5a78ff" : "rgba(229, 229, 229, 1)"} !important;
    color: ${({ isActive }) => isActive ? "white" : "rgba(153, 153, 153, 1)"};
  }

`

const MethodButton = styled.button`
  width: 100%;
  height: 34px;
  border: solid 1px ${({ isActive }) => isActive ? "rgba(90, 120, 255, 1)" : "rgba(213, 213, 213, 1)"};
  background-color: ${({ isActive }) => isActive ? "rgba(230, 234, 255, 1)" : "white"};
  border-radius: 8px;
  padding-left: 12px;

  display: flex;
  align-items: center;
  justify-content: start;
  gap: 8px;

  font-size: 12px;
  font-weight: 400;
  line-height: 14.52px;
  text-align: left;
  color: ${({ isActive }) => isActive ? "rgba(90, 120, 255, 1)" : "rgba(81, 81, 81, 1)"};


  img{
    width: 18px;
    height: 18px;
  }
`
const PopUp = styled.div`
  background-color: rgba(221, 248, 254, 1);
  padding: 8px 12px 8px 8px;
  border-radius: 4px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;

  p{
    display: flex;
    align-items: center;
    gap: 8px;

    font-size: 12px;
    font-weight: 700;
    line-height: 14.52px;

  }

  span{
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    padding-left: 26px;

  }
`
const PixArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;

  margin-top: 16px;

  .box{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;

    margin-inline: 29px;
    
    img{
      width: 143.84px;
      height: 143.84px;
    }

    p{
      border: solid 1px rgba(213, 213, 213, 1);
      border-radius: 4px;
      padding: 12px 16px;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  button{
    height: 48px;
    width: 100%;
    background-color: rgba(90, 120, 255, 1);
    border: none;
    border-radius: 4px;
    color: white;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }
`