// Dependencias

import React, { useState } from 'react'
import styled from 'styled-components'
import { Container, Row, Col } from 'react-bootstrap'
import moment from 'moment'
import SapService from '../../services/SapService';
import MagicService from '../../services/MagicService';
import Lockr from 'lockr';
import LoaderAnimation from '../LoaderAnimation'

// Components

import LooviSelect from '../styles/LooviSelect'
import Body from '../Body'
import * as masks from '../../helpers/masks'

// Images

import credtiCardPlusImage from '../../assets/images/credit-card-plus-icon.png'
import mastercardImage from '../../assets/images/card-mastercard.png'
import visaImage from '../../assets/images/card-visa.png'
import cardIcon from "../../assets/icons/icon-credit-card.svg"
import plusIcon from "../../assets/icons/circle-plus-blue.svg"
import cardPayIcon from "../../assets/icons/credit-card-pay-blue.svg"

export const PaymentHistoryMobile = ({ customerCars, customerSelectedCar, handleSelectChange, payments }) => {
    const [isLoading, setLoading] = useState(false)
    let items = 0
    let valorTotal = 0

    let customerCards = []
    const clientData = Lockr.get('ClientData')
    // customerCards = clientData.customerCars.reduce((acc, contract) => {
    //     const { cardSummary, pmCard } = contract.contractInfo
    //     return [...acc, { label: pmCard, cardNum: `**** ${cardSummary}`, cardImage: visaImage }];
    // }, []);
    const { cardSummary, pmCard } = clientData.customerSelectedCar.contractInfo

    if (!!cardSummary && !!pmCard) {
        customerCards = [{ label: pmCard === 'mc' ? 'Cartão de Crédito Mastercard' : pmCard === 'visa' ? 'Cartão de Crédito Visa' : 'Cartão de Crédito Amex', cardNum: `**** **** **** ${cardSummary}`, cardImage: pmCard === 'mc' ? mastercardImage : visaImage }];
    } else {
        customerCards = [{ label: pmCard, cardNum: `**** **** **** ${cardSummary}`, cardImage: credtiCardPlusImage }];
    }

    if (payments) {
        items = payments.filter(payment => payment.billStatus.idStatus === 'I')
        valorTotal = items.reduce((acc, current) => {
            const value = acc + (current.docTotal + current.valorMulta + current.valorJuros)
            return value;
        }, 0)
    }

    const callReactivation = async () => {
        await setLoading(true)

        try {
            const response = await SapService.getSellers();

            if (response) {
                if (response.sucess) {
                    let sellerId = response.sellers.filter(seller => {
                        return seller.email === "lia@loovi.com.br";
                    });
                    //console.log('sellerId.slpCode',sellerId[0].slpCode);
                    const result = await MagicService.reactivation({
                        contrato: payments[0].nCtrId,
                        faturas: items.map(item => ({ fatura: item.docEntry })),
                        valorTotal,
                        IdSAP__c: sellerId[0].slpCode
                    });
                    //console.log(result)

                    if (result) {
                        if (result.retorno === "FALSE" || result.retorno === "false") {
                            alert(result.mensagemRetorno);
                        } else {
                            window.location.reload();
                            //history.push("/pagamentos");
                        }
                    } else {
                        alert('Ops, ocorreu um erro ao processar o pagamento.');
                    }
                }
            }
            //console.log(response) 

        } catch (error) {
            alert('Ops, ocorreu um erro ao processar o pagamento.')
        }

        await setLoading(false)
    }

    return (
        <Section>
            <LoaderAnimation show={isLoading} />
            <div className="loovi-sub-header">
                <Header>
                    <h2>PAGAMENTOS</h2>
                    <div>
                        <p>Selecione o veículo</p>
                        <LooviSelect
                            classNamePrefix="react-select"
                            value={customerSelectedCar}
                            onChange={handleSelectChange}
                            options={customerCars}
                        />
                    </div>
                </Header>

                <CardBox>
                    <h3>Cartão cadastrado</h3>
                    <CardInfo>
                        <img src={cardIcon} />
                        <div>
                            <p>Cartão de Crédito {pmCard}</p>
                            <span>*** *** *** {cardSummary}</span>
                        </div>

                    </CardInfo>
                    <CardButtons>
                        <a href='/pagamentos/adicionar-cartao'>Trocar cartão <img src={plusIcon} /></a>
                        <a href='antecipacao'>Antecipar pagamento <img src={cardPayIcon} /></a>
                    </CardButtons>
                </CardBox>
            </div>
            <PaymentList>
                <h3>Histórico de pagamento</h3>

                <ul>
                    {payments && payments.map(payment => (
                        <li>
                            <p>{moment(payment.docDueDate).format('MM/YYYY')}</p>
                            <p>{masks.real(payment.docTotal + payment.valorMulta + payment.valorJuros)}</p>
                            <p style={{ color: payment.billStatus.descStatus === "Inadimplente" ? "rgba(255, 90, 90, 1)" : "rgba(153, 153, 153, 1)" }}>{payment.billStatus.descStatus}</p>
                        </li>
                    ))}
                </ul>

                {!!valorTotal &&
                    <div>
                        <p>Total em aberto:</p>
                        <p>{masks.real(valorTotal)}</p>
                    </div>
                }
            </PaymentList>

            {!!valorTotal &&
                <Footer>
                    <a href='/pagamentos/fatura-em-aberto'>Pagar fatura em aberto</a>
                </Footer>
            }

            {/* <Container className="loovi-full">
                <Body className="loovi-list">
                    <h4>Histórico de Pagamentos</h4>

                    {payments && payments.map(payment => (
                        <div className="loovi-list-item loovi-color-4 d-flex justify-content-between">
                            <div className="loovi-list-desc">
                                <span className="loovi-bold">{moment(payment.docDueDate).format('MM/YYYY')}</span> - {masks.real(payment.docTotal + payment.valorMulta + payment.valorJuros)} - {payment.billStatus.descStatus}
                            </div>
                            <div className="loovi-list-action">

                            </div>
                        </div>
                    ))}

                    <span class="loovi-spacer"></span>

                    {!!valorTotal && (
                        <div class="loovi-cupom d-flex justify-content-between" style={{ alignItems: "center", marginLeft: 10 }}>
                            <span className="loovi-bold">
                                Total em aberto: {masks.real(valorTotal)}
                            </span>
                            <a href="#pagar" class="loovi-change-card" style={{ color: "white", marginRight: 10, }} onClick={callReactivation}>
                                Pagar Agora
                            </a>
                        </div>
                    )}
                </Body>
            </Container> */}
        </Section>
    )
}

export default PaymentHistoryMobile

const Section = styled.section`
    position: relative;

    display: flex;
    flex-direction: column;
    gap: 24px;

    .loovi-sub-header{
        padding: 0;
        background-color: white;
    }
`

const Header = styled.div`
    background-color: rgba(90, 120, 255, 1);
    padding: 0 16px 32px 16px ;

    h2{
        font-size: 14px !important;
        font-weight: 700 !important;
        line-height: 18px;
        letter-spacing: 0.12em;
        text-align: center;
        color: white;

    }

    p{
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        text-align: left;
        color: white;
        margin-bottom: 4px;

    }
`

const CardBox = styled.div`
    padding-inline: 16px;
    padding-top: 16px;

    display: flex;
    flex-direction: column;
    gap: 24px;

    h3{
        font-size: 16px !important;
        font-weight: 700 !important;
        line-height: 20px;
        text-align: left;
        margin-bottom: 16px;
    }
`

const CardInfo = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;

    p{
        color: rgba(153, 153, 153, 1);
        font-size: 14px;
        font-weight: 700;
        line-height: 18px;
        text-align: left;
        margin-bottom: 4px;
    }

    span{
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        text-align: left;
        color: rgba(90, 120, 255, 1);

    }
`

const CardButtons = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;

    a{
        width: 100%;
        height: 44px;
        border: solid 1px rgba(90, 120, 255, 1);
        color: rgba(90, 120, 255, 1);
        border-radius: 4px;
        background-color: white;
        padding-inline: 32px;

        display: flex;
        align-items: center;
        justify-content: space-between;
    }
`

const PaymentList = styled.div`

    h3{
        font-size: 16px !important;
        font-weight: 700 !important;
        line-height: 20px;
        text-align: left;
        margin-bottom: 16px;
        padding-inline: 16px;
    }

    ul{
        margin: 0;
        padding: 0;
    }

    li{
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: rgba(153, 153, 153, 1);

        padding-block: 12px;
        border-bottom: solid 1px rgba(229, 229, 229, 1);
        max-height: 42px;
    }

    p{
        margin: 0;
    }

    li > p:first-child{
        padding-left: 16px;
    }

     li> p:last-child{
        min-width: 97px;
        text-align: end;
        margin-right: 16px;
    }

    & > div{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-inline: 16px;
        padding-top: 12px;

        p{
            font-size: 14px;
            font-weight: 700;
            line-height: 18px;
            text-align: left;
            display: inline-block;

        }
    }
    
`

const Footer = styled.div`
    width: 100%;
    padding-inline: 16px;

    a{
        height: 44px;
        width: 100%;
        background-color: rgba(90, 120, 255, 1);
        color: white;
        border: none;
        border-radius: 4px;

        display: flex;
        align-items: center;
        justify-content: center;
    }
`