// Dependencias

import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

// Componentes

import Header from '../Header'
import NavBar from './NavBar'
import LooviSelect from '../styles/LooviSelect'
import FormCreditCard from './FormCreditCard'
import creditCard from "../../assets/images/credit-card.svg"

// Imagens

import cardModelImage from '../../assets/images/modelo-cartao.png'
import { ModalSuccess } from './Payment/ModalSuccess'

export const AddCreditCardMobile = (props) => {
    const [cardSummary, setCardSummary] = useState("**** **** ****")

    useEffect(() => {
        if (props.values?.customerSelectedCar?.contractInfo?.cardSummary) {

            setCardSummary(`**** **** **** ${props.values?.customerSelectedCar?.contractInfo?.cardSummary}`)
        }
    }, [])

    return (
        <Section>
            <Header background="#5A78FF">
                <NavBar background="#5A78FF" titleColor="#FFFFFF" backButton burgerWhite >Trocar Cartão</NavBar>
                <h3>TROCA DE CARTÃO</h3>
            </Header>
            <Container className="loovi-align-left">
                <BoxSelectCar>
                    <p className="loovi-center loovi-bold">Selecione o veículo</p>
                    <LooviSelect
                        classNamePrefix="react-select"
                        value={props.values.customerSelectedCar}
                        onChange={props.handleSelectChange}
                        options={props.values.customerCars} />
                    <span className="loovi-spacer"></span>
                </BoxSelectCar>
                <BoxCard>
                    <Card>
                        <p>Nome</p>
                        <span>{cardSummary}</span>
                    </Card>
                    <div className='vector' />
                </BoxCard>

                <FormCreditCard
                    isAddCreditCard
                    values={props.values}
                    handleSelectChange={props.handleSelectChange}
                    isChangeCreditCard={true}
                    //handleChange={props.handleChange}
                    proceed={props.proceed}
                />
            </Container>
        </Section>
    )
}

const Section = styled.section`

    position: relative;

    h3{
        font-size: 14px!important;
        font-weight: 700;
        line-height: 18px;
        letter-spacing: 0.12em;
        text-align: center;
        color: white;
        margin: 0;

    }
`

const Container = styled.div`
`

const BoxSelectCar = styled.div`
    background-color: #5A78FF;
    padding-inline: 16px;
    padding-top: 32px;

    p{
        margin: 0;
        color: white;
        text-align: start;
    }
`

const BoxCard = styled.div`
    position: relative;
    background-color: #5A78FF;
    /* padding-inline: 16px; */
    height: 180px;

    display: flex;
    align-items: end;

    .vector{
        height: 50%;
        width: 100%;
        background-color: white;
    }
`

const Card = styled.div`
    width: 328px;
    height: 180px;
    background-image: url(${creditCard});

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    p{
        font-size: 15.67px;
        font-weight: 400;
        line-height: 23.5px;
        letter-spacing: 0.5px;
        text-align: left;
        margin: 0;
        color: white;

        position: absolute;
        top: 103px;
        left: 23.5px;

    }

    span{
        font-size: 20px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.16em;
        text-align: left;
        color: white;

        position: absolute;
        top: 134px;
        left: 23.5px;
    }
`
export default AddCreditCardMobile
