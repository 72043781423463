// Dependencias

import React, { useEffect } from "react";
import styled from "styled-components";
import * as masks from "../../helpers/masks";

export const RegisterMobile = ({ data }) => {
  const name = data.name?.toLowerCase()
    .split(' ')
    .map(
      word =>
        word.charAt(0).toUpperCase() +
        word.slice(1)
    )
    .join(' ');

  const handleRedirect = () => {
    window.location.href = '/suporte?area=Alteracao-de-Dados-Cadastrais'
  };

  return (
    <Section>
      <Container>
        <div>
          <h4>Dados Pessoais</h4>
          <BoxData>
            <p>{name}</p>
            <p>CPF: {masks.cpf(data.cpf)}</p>
            <p>Fone: {masks.phone(data.phone)}</p>
            <p>Email: {data.email}</p>
          </BoxData>
        </div>
        {data && data.address && (
          <div>
            <h4>Endereço</h4>
            {data.address
              .filter((a) => a.addressType === "Cobrança")
              .map((address) => (
                <BoxData
                  key={address.addressType}

                >
                  <p>
                    {address.street}, {address.streetNo}{" "}
                    {address.building ? ` - ${address.building}` : ""}
                  </p>
                  <p>
                    {address.block}, {address.city} - {address.state}
                  </p>
                  <p>CEP: {masks.cep(address.zipCode)}</p>
                </BoxData>
              ))}
          </div>
        )}
      </Container>
      <Button onClick={handleRedirect}>
        Alterar Dados Cadastrais
      </Button>
    </Section>
  );
};

const Section = styled.section`
  display: flex;
  flex-direction: column;
  gap: 124px;

  padding-inline: 16px;

`;

const Container = styled.main`
  display: flex;
  flex-direction: column;
  gap: 32px;

  h4{
    font-size: 16px !important;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    color: rgba(81, 81, 81, 1);
  }
`

const BoxData = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  p{
    color: rgba(161, 164, 171, 1);
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;

    margin: 0;
  }
`

const Button = styled.button`
  background-color: white;
  border: 1px solid  rgba(90, 120, 255, 1);
  width: 100%;
  height: 48px;
  
  color: rgba(90, 120, 255, 1);
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
  border-radius: 4px;



`