// Dependencias

import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Lockr from 'lockr';

// Componentes

import Header from '../Header'
import NavBar from './NavBar'
import PaymentHistoryMobile from './PaymentHistoryMobile'
import PaymentHistoryBrowser from './PaymentHistoryBrowser'
import { MobileView, BrowserView } from "react-device-detect";
import { authHandler } from '../../helpers/auth.js'
import { getHistoricoDePagamento } from './services.js'

// Imagens
import creditCardImage from '../../assets/images/credit-card-icon.png'
import buttonMenu from "../../assets/icons/button-menu-white.svg"
import LoaderAnimation from '../LoaderAnimation.js';

export const PaymentsHistory = (props) => {
    const [customerCars, setCustomerCars] = useState([])
    const [customerInfo, setCustomerInfo] = useState({})
    const [customerSelectedCar, setCustomerSelectedCar] = useState({})
    const [payments, setPayments] = useState([])
    const [isLoading, setLoading] = useState(false)

    useEffect(async () => {
        let value;
        let clientData;

        if (authHandler.isLogged()) {
            if (props.nCtrId) {
                clientData = Lockr.get('ClientData');
                value = clientData.customerCars.filter(contracts => {
                    return contracts.contractInfo.nCtrId === this.props.nCtrId;
                });


                Lockr.set('ClientData', {
                    IdCustomerSelectedCar: value[0].value,
                    customerCars: clientData.customerCars,
                    customerSelectedCar: value[0],
                    customerInfo: clientData.customerInfo
                });
            }
            clientData = Lockr.get('ClientData')
            const payments = await getHistoricoDePagamento(clientData.customerSelectedCar.contractInfo.nCtrId)

            setPayments(payments)
            setCustomerCars(clientData.customerCars)
            setCustomerInfo(clientData.customerInfo)
            setCustomerSelectedCar(clientData.customerSelectedCar)

        }
    }, [])

    const handleSelectChange = async selectedOption => {
        setLoading(true)

        try {
            const payments = await getHistoricoDePagamento(selectedOption.contractInfo.nCtrId);
            const clientData = Lockr.get('ClientData')
            Lockr.set('ClientData', {
                ...clientData,
                customerSelectedCar: selectedOption,
            });

            setPayments(payments)
            setCustomerSelectedCar(selectedOption)
        } catch (error) {
            console.error("Erro ao buscar histórico de pagamento!", error)
        }

        setLoading(false)
    };

    return (
        <Section>
            <Header background="rgba(90, 120, 255, 1)" >
                <NavBar backButton background="rgba(90, 120, 255, 1)" />
            </Header>
            <LoaderAnimation show={isLoading} />
            <MobileView>
                <PaymentHistoryMobile
                    customerCars={customerCars}
                    customerSelectedCar={customerSelectedCar}
                    handleSelectChange={handleSelectChange}
                    payments={payments}
                />
            </MobileView>
            <BrowserView>
                <PaymentHistoryBrowser
                    customerCars={customerCars}
                    customerSelectedCar={customerSelectedCar}
                    handleSelectChange={handleSelectChange}
                    payments={payments}
                />
            </BrowserView>
        </Section>
    )
}

const Section = styled.section`
    position: relative;
    font-size:15px;

    .bm-burger-button{
        background-image: url(${buttonMenu});
        width: 32px;
        height: 32px;
    }


    .loovi-info-data{

        p{

            margin: 0;
        }
    }

    .loovi-payment-config{

        background: #EFF2F7;
    }

    .loovi-align-left{

        text-align: left !important;
    }

    .loovi-cartao-info{

        padding-left: 70px;
        background-image: url(${creditCardImage});
        background-repeat: no-repeat;
        background-size: 58px 39px;
    }

    .loovi-change-card{

        color: #FFFFFF;
        background: #57D9A3;
        border-radius: 5px;
        padding: 8px 25px;
        display: inline-block;
        margin: 0 auto;
        font-family: 'BrownStd-Regular';
        font-size: 16px;

        &:hover{

            background: #5A78FF;
        }

        img{

            width: 24px;
            height: 19px;
            margin-left: 5px;
        }
    }

    .loovi-list{

        h4{

            font-size: 12px;
            color: #9FA5B3;
            letter-spacing: 1.8px;
            text-align: center;
            text-transform: uppercase;
        }
    }

    .loovi-list-item{

        padding: 15px 25px;
        color: #899299;
        border-bottom: solid 1px #D8D8D8;
    }

    .loovi-payment-link{

        color: #5A78FF;
        border-bottom: solid 2px #5A78FF;

        &:hover{

            color: #57D9A3;
            border-color: #57D9A3;
        }
    }

    .loovi-download{

        img{

            width: 16px;
            height: auto;
        }
    }
`
