import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import iconMobile from '../../../../assets/icons/icon-mobile.svg'
import iconCopy from '../../../../assets/icons/icon-copy.svg'
import { api_keyed, URLS } from '../../../../helpers/Request'
import SapService from '../../../../services/SapService'
import iconClose from "../../../../assets/icons/icon-close.svg"

export const PixArea = ({ nCtrId, amountValue, docEntry, setInfoPix, infoPix }) => {
  const [isLooding, setIsLooding] = useState(false)
  const [isOutstandingInvoice, setIOutstandingInvoice] = useState(false)

  useEffect(() => {
    (async () => {
      try {
        setIsLooding(true)
        const result = await api_keyed.get(
          `${URLS.SALES_FORCE}/consultas/caso_cobranca_por_chaveNatural/${nCtrId}`
        ).json();

        const ticket = result[0].CaseNumber
        const response = await SapService.generatePix(nCtrId, amountValue, docEntry, ticket)

        if (response?.sucesso) {
          setInfoPix(response.conteudo);
          setIsLooding(false)
        } else {
          if (response.mensagem.includes("faz parte de uma negociação em aberto")) {
            setIOutstandingInvoice(true)
          }
        }
      } catch (error) {
        console.log(error)
      }
    })()

  }, [])

  const handleCopy = (event) => {
    event.preventDefault()
    const tempInput = document.createElement('input');
    tempInput.value = infoPix.chaveAleatoria
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);
  }

  return (
    <Container>
      <PopUp>
        <p>
          <img src={iconMobile} alt="" />
          Pagamento com Pix
        </p>
        <span>
          Copie o código Pix, acesse a seção Pix no app do seu banco e cole o código para efetuar o pagamento.
        </span>
      </PopUp>

      {isLooding ? (
        <div className='loader' />
      ) : <>
        <div className="box">
          <img src={infoPix.link} alt="" />
          <p>{infoPix.chaveAleatoria}</p>
        </div>

        <button onClick={handleCopy}><p>Copiar Pix</p> <img src={iconCopy} /></button>
      </>}

      {
        isOutstandingInvoice && (
          <PopUpOutstandingInvoice>
            <div>
              <div>
                <h2>A fatura faz parte de uma negociação em aberto!</h2>
              </div>

              <button onClick={(e) => {
                e.preventDefault()
                window.location.href = "/pagamentos"
              }}>Continuar</button>
            </div>
          </PopUpOutstandingInvoice>
        )
      }

    </Container>
  )
}


const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;

  .box{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;

    margin-inline: 29px;
    
    img{
      width: 143.84px;
      height: 143.84px;
    }

    p{
      border: solid 1px rgba(213, 213, 213, 1);
      border-radius: 4px;
      padding: 12px 16px;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  button{
    height: 48px;
    width: 100%;
    background-color: rgba(90, 120, 255, 1);
    border: none;
    border-radius: 4px;
    color: white;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }

  button > p {
    margin: 0;
    font-size: 14px;
    font-weight: 700;
    line-height: 16.94px;
    text-align: center;

  }

  .loader {
    width: 48px;
    height: 48px;
    border: 5px solid #FFF;
    border-bottom-color: rgba(90, 120, 255, 1);
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;

    margin-inline: auto;
    }

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
    } 


`
const PopUp = styled.div`
  background-color: rgba(221, 248, 254, 1);
  padding: 8px 12px 8px 8px;
  border-radius: 4px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;

  p{
    display: flex;
    align-items: center;
    gap: 8px;

    font-size: 12px;
    font-weight: 700;
    line-height: 14.52px;

    margin: 0;

  }

  span{
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    padding-left: 26px;

  }
`
const PopUpOutstandingInvoice = styled.div`
 position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
    
  width: 100vw;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  background-color: rgba(81, 81, 81, 0.25);

    & > div{
        width: 90%;
        max-width: 470px;
        border-radius: 12px;
        background-color: white;
        padding: 16px 16px 32px 16px;

        position: relative;

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        div{
            display: flex;
            flex-direction: column;
            gap: 32px;
        }

        h2{
          font-weight: 700 !important;
          font-size: 20px !important;
          align-items: center;
          color: rgba(81, 81, 81, 1) !important;
          line-height: 24.2px;
          letter-spacing: normal;

          text-transform: capitalize;
          text-align: center;
        }

        p{
            color: rgba(161, 164, 171, 1);
            font-size: 14px;
        }

    }

    img{
        width: 24px;
        height: 24px;

        position: absolute;
        top: 16px;
        right: 16px;
    }

    button{
        background-color: rgba(90, 120, 255, 1);
        color: white;
        height: 46px;
    }

    
`
