// Dependencias

import React from 'react'
import styled from 'styled-components'
import * as masks from '../../helpers/masks'

// Componentes

import { Container, Row, Col } from 'react-bootstrap'
import Sidebar from '../painel/Sidebar'
import LoaderAnimation from '../LoaderAnimation'

export const RegisterBrowser = ({ data }) => {
    let isLoading = false
    if (!data.name) isLoading = true

    const handleRedirect = () => {
        window.location.href = '/suporte?area=Alteracao-de-Dados-Cadastrais'
    };

    return (
        <Section>
            <Container fluid>
                <Row>
                    <div className="loovi-fixed-side-column">
                        <Sidebar />
                    </div>
                    <div className="loovi-fluid-column">
                        <div className="loovi-descktop-padding">
                            {isLoading && <LoaderAnimation show={true} />}

                            {!isLoading && (
                                <>
                                    <Row className="loovi-descktop-row">
                                        <Col>
                                            <h4 className="loovi-color-2">Dados Pessoais</h4>
                                            <div className="loovi-info-data loovi-color-5">
                                                <p>{data.name}</p>
                                                {data.cpf ? (
                                                    <p>CPF: {masks.cpf(data.cpf)}</p>
                                                ) : (
                                                    <p>CNPJ: {masks.cnpj(data.cnpj)}</p>
                                                )}
                                                <p>Fone: {masks.phone(data.phone)}</p>
                                                <p>Email: {data.email}</p>
                                            </div>
                                        </Col>
                                        <Col>
                                            {data && data.address &&
                                                data.address.filter(a => a.addressType === 'Cobrança').map((address) => (
                                                    <>
                                                        <h4 className="loovi-color-2">Endereço de {address.addressType}</h4>
                                                        <div key={address.addressType} className="loovi-info-data loovi-color-5">
                                                            <p>{address.street}, {address.streetNo} {(address.building) ? ` - ${address.building}` : ''}</p>
                                                            <p>{address.block}, {address.city} - {address.state}</p>
                                                            <p>CEP: {masks.cep(address.zipCode)}</p>
                                                        </div>
                                                    </>
                                                ))
                                            }
                                        </Col>
                                    </Row>
                                    <span className="loovi-spacer" />
                                    <button className='btn-change-data' onClick={handleRedirect}>
                                        Alterar Dados Cadastrais
                                    </button>
                                </>
                            )}
                        </div>
                    </div>
                </Row>
            </Container>
        </Section>
    )
}

const Section = styled.section`
    position: relative;
`
