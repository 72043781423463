import Lockr from "lockr";
import { authHandler } from "../../helpers/auth";
import { URLS, API_BANCO, api_keyed } from "../../helpers/Request";
import { decode as base64_decode, encode as base64_encode } from "base-64";

// Versão original
async function getContratos() {
  let contratos = [];
  let dados;
  const cpfOrCnpj = authHandler.getCPForCNPJ() || "";

  let currentPage = 1;
  const itemsPerPage = 5;

  if (cpfOrCnpj) {
    try {
      let hasMoreContracts = true;

      while (hasMoreContracts) {
        let encodedString = base64_encode(
          `{"url": "${URLS.SAP}/GetClient/${cpfOrCnpj}/${API_BANCO}?quantidade=${itemsPerPage}&pagina=${currentPage}", "metodo": "GET", "headers" : { "requester": "SevenApp" }}`
        );

        const result = await api_keyed
          .post(`${URLS.URLPROXY}/SAP`, { json: encodedString })
          .json();

        dados = JSON.parse(base64_decode(result));

        if (
          dados.businessPartner &&
          dados.businessPartner.salesContracts &&
          dados.businessPartner.salesContracts.length > 0
        ) {

          const contratosValidos = dados.businessPartner.salesContracts.filter(
            (contract) => {
              return (
                contract.cancelled === false && contract.status !== "Encerrado"
              );
            }
          );

          contratos = contratos.concat(contratosValidos);

          if (dados.businessPartner.salesContracts.length < itemsPerPage) {
            hasMoreContracts = false;
          } else {
            currentPage++;
          }
        } else {
          hasMoreContracts = false;
        }
      }

      if (contratos.length > 0) {
        const selectedCar = contratos
          .map((contract) => ({
            ...contract.vehicle,
            contractInfo: contract,
          }))
          .map((vehicle, key) => ({
            contractInfo: vehicle.contractInfo,
            label: vehicle.vModel
              ? `${vehicle.numberPlate} - ${vehicle.vModel}`.toUpperCase()
              : vehicle.numberPlate
              ? vehicle.numberPlate.toUpperCase()
              : `Dispositivo ${key + 1}`,
            value: `${key}-${vehicle.numberPlate}`,
          }));

        let customerSelectedCar;
        const clientData = Lockr.get("ClientData");
        if (clientData && clientData.customerSelectedCar) {
          customerSelectedCar = clientData.customerSelectedCar;
        } else {
          customerSelectedCar = selectedCar[0];
        }

        const nCtrId = customerSelectedCar.contractInfo.nCtrId;
        const contratosIndication = await getIndications(nCtrId);

        customerSelectedCar = {
          ...customerSelectedCar,
          contractDiscounts: contratosIndication.activeIndicationCtt,
        };

        Lockr.set("ClientData", {
          IdCustomerSelectedCar: customerSelectedCar.value,
          customerCars: selectedCar,
          customerSelectedCar: customerSelectedCar,
          customerInfo: {
            key: dados.businessPartner.addressType,
            name: dados.businessPartner.cardName,
            email: dados.businessPartner.e_Mail,
            phone: dados.businessPartner.phone1,
            cpf: dados.businessPartner.fiscalID.cpf,
            cnpj: dados.businessPartner.fiscalID.cnpj,
            address: dados.businessPartner.bpAddresses,
          },
        });
      }
      return;
    } catch (error) {
      console.error(error);
      return;
    }
  }
}

async function getIndications(contractId) {
  let encodedString = base64_encode(
    `{"url": "${URLS.SAP}/checkindicationctt", "metodo": "GET", "headers" : { "requester": "SevenApp", "nctrid": "${contractId}" }}`,
  );
  try {
    const result = await api_keyed
      .post(`${URLS.URLPROXY}/SAP`, { json: encodedString })
      .json();

    const dados = JSON.parse(base64_decode(result));

    return dados;
  } catch (e) {
    console.log(e);
    return false;
  }
}

const getHistoricoDePagamento = async (nCtrId) => {
  const cpfOrCnpj = authHandler.getCPForCNPJ() || "";

  let encodedString = base64_encode(
    `{"url": "${URLS.SAP}/CheckBills", "metodo": "GET", "headers" : { "requester": "SevenApp", "banco": "${API_BANCO}", "cpfcnpj": "${cpfOrCnpj}", "nctrid": "${nCtrId}" }}`,
  );
  console.log(
    `{"url": "${URLS.SAP}/CheckBills", "metodo": "GET", "headers" : { "requester": "SevenApp", "banco": "${API_BANCO}", "cpfcnpj": "${cpfOrCnpj}", "nctrid": "${nCtrId}" }}`,
  );
  try {
    const result = await api_keyed
      .post(`${URLS.URLPROXY}/SAP`, { json: encodedString })
      .json();

    const pagamentos = JSON.parse(base64_decode(result));
    console.log("CSH -> ", pagamentos.returnBills);

    return pagamentos.returnBills;
  } catch (error) {
    console.error(error);
    return;
  }
};

export { getContratos, getIndications, getHistoricoDePagamento };
