import React, { useEffect, useState } from "react";
import useForm from "react-hook-form"

import iconPix from '../../../../assets/icons/icons-pix.svg'
import iconCard from '../../../../assets/icons/icon-credit-card-gray.svg'
import iconMobile from '../../../../assets/icons/icon-mobile.svg'
import iconCopy from '../../../../assets/icons/icon-copy.svg'
import styled from "styled-components";
import { ModalSuccess } from "../ModalSuccess";
import { PaymentInput } from "../PaymentInput";
import Header from "../../../Header";
import { NavBar } from "../../NavBar";
import SapService from "../../../../services/SapService";
import MagicService from "../../../../services/MagicService";
import { getHistoricoDePagamento } from "../../services";
import Lockr from 'lockr';
import { PixArea } from "../PìxArea";
import FormCreditCard from "../../FormCreditCard";
import * as masks from '../../../../helpers/masks'
import cardIcon from "../../../../assets/icons/icon-credit-card.svg"
import credtiCardPlusImage from '../../../../assets/images/credit-card-plus-icon.png'
import mastercardImage from '../../../../assets/images/card-mastercard.png'
import visaImage from '../../../../assets/images/card-visa.png'
import plusIcon from "../../../../assets/icons/circle-plus-blue.svg"

export const PayOpenInvoice = () => {
  const [isModal, setIsModal] = useState(false)
  const [currentPaymentMethod, setCurrentPaymentMethod] = useState("")
  const [isChangeCreditCard, setIsChangeCreditCard] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [amontValue, setAmontValue] = useState(0)
  const [docEntry, setDocEntry] = useState([])
  const [infoPix, setInfoPix] = useState({
    rnId: "",
    dataExpiracao: "",
    numeroInterno: 0,
    pspReference: "",
    chaveAleatoria: "",
    link: ""
  })

  const isSelectPix = currentPaymentMethod === 'PI'
  const isSelectCreditCard = currentPaymentMethod === 'CC'
  let items = []

  const clientData = Lockr.get('ClientData')
  const nCtrId = clientData.customerSelectedCar.contractInfo.nCtrId

  const { register, handleSubmit, errors, clearError } = useForm();

  let customerCards = []
  const { cardSummary, pmCard } = clientData.customerSelectedCar.contractInfo

  if (!!cardSummary && !!pmCard) {
    customerCards = [{ label: pmCard === 'mc' ? 'Cartão de Crédito Mastercard' : pmCard === 'visa' ? 'Cartão de Crédito Visa' : 'Cartão de Crédito Amex', cardNum: `**** **** **** ${cardSummary}`, cardImage: pmCard === 'mc' ? mastercardImage : visaImage }];
  } else {
    customerCards = [{ label: pmCard, cardNum: `**** **** **** ${cardSummary}`, cardImage: credtiCardPlusImage }];
  }

  useEffect(() => {
    (async () => {
      try {
        const payments = await getHistoricoDePagamento(nCtrId)
        items = payments.filter(payment => payment.billStatus.idStatus === 'I')

        setDocEntry(items.map(element => {
          return { "docEntry": element.docEntry }
        }))

        if (payments) {
          setAmontValue(items.reduce((acc, current) => {
            const value = acc + (current.docTotal + current.valorMulta + current.valorJuros)
            return value;
          }, 0))
        }
      } catch (error) {

      }
    })()
  }, [])


  const callReactivation = async () => {
    setLoading(true)
    try {
      const response = await SapService.getSellers();

      if (response.sucess) {
        let sellerId = response.sellers.filter(seller => {
          return seller.email === "lia@loovi.com.br";
        });

        const result = await MagicService.reactivation({
          contrato: nCtrId,
          faturas: items.map(item => ({ fatura: item.docEntry })),
          valorTotal: amontValue,
          IdSAP__c: sellerId[0].slpCode
        });

        if (result) {
          if (result.retorno === "FALSE" || result.retorno === "false") {
            alert(result.mensagemRetorno);
          } else {
            window.location.reload();
          }
        } else {
          alert('Ops, ocorreu um erro ao processar o pagamento.');
        }
      }
      setIsModal(true)

    } catch (error) {
      alert('Ops, ocorreu um erro ao processar o pagamento.')
    }

    setLoading(false)
  }

  return (
    <Container>
      <Header background="white" >
        <NavBar backButton background="#EFF2F7" blueArrow />
      </Header>

      <FormStyled>
        <h3>PAGAMENTO FATURA EM ABERTO</h3>

        <PaymentValue>
          <p>Total em aberto:</p>
          <p>{masks.real(amontValue)}</p>
        </PaymentValue>

        <PaymentMethod>
          <p>Escolha a forma de pagamento</p>

          <div>
            <MethodButton
              onClick={() => setCurrentPaymentMethod("PI")}
              type="button"
              isActive={isSelectPix}
            >
              <img src={iconPix} /> Pix
            </MethodButton>
            <MethodButton
              onClick={() => setCurrentPaymentMethod("CC")}
              type="button"
              isActive={isSelectCreditCard}
            >
              <img src={iconCard} /> Cartão de Credito
            </MethodButton>
          </div>

        </PaymentMethod>


        {isSelectPix &&
          <PixArea amountValue={amontValue} nCtrId={nCtrId} docEntry={docEntry} infoPix={infoPix} setInfoPix={setInfoPix} />
        }



        {isSelectCreditCard &&
          <>
            {isChangeCreditCard ?
              <FormCreditCard
                isAddCreditCard
                isChangeCreditCard={true}
                proceed={callReactivation}
              />
              :
              <CreditCard>
                <div>
                  <h3>Cartão cadastrado</h3>
                  <CardInfo>
                    <img src={cardIcon} />
                    <div>
                      <p>Cartão de Crédito {pmCard}</p>
                      <span>*** *** *** {cardSummary}</span>
                    </div>

                  </CardInfo>

                  <button onClick={() => setIsChangeCreditCard(true)}>
                    Trocar cartão
                    <img src={plusIcon} />
                  </button>
                </div>

                <button className="loovi-button" onClick={callReactivation} >
                  Confirmar pagamento
                </button>
              </CreditCard>
            }
          </>
        }

        {isModal &&
          <ModalSuccess text="Pagamento confirmado" onClose={() => setIsModal(false)} />
        }
      </FormStyled>
    </Container>
  )
}

const Container = styled.div`
 height: 100vh;
`

const FormStyled = styled.form`
  height: calc(100% - 80px);

  display: flex;
  flex-direction: column;
  gap: 32px;

  margin-inline: 16px;

  h3{
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.12em;
    text-align: center;
    color: rgba(90, 120, 255, 1);

    margin: 0;
  }

  form{
    margin: 0;
    padding: 0;
    height: 100%;
  }
`

const PaymentValue = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  p{
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    text-align: left;

  }
`

const PaymentMethod = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  p{
    font-size: 15px;
    font-weight: 700;
    line-height: 18.15px;
    letter-spacing: -0.02em;
    text-align: left;

  }

  div{
    display: flex;
    align-items: center;
    gap: 12px;
  }


`

const MethodButton = styled.button`
  width: 100%;
  height: 34px;
  border: solid 1px ${({ isActive }) => isActive ? "rgba(90, 120, 255, 1)" : "rgba(213, 213, 213, 1)"};
  background-color: ${({ isActive }) => isActive ? "rgba(230, 234, 255, 1)" : "white"};
  border-radius: 8px;
  padding-left: 12px;

  display: flex;
  align-items: center;
  justify-content: start;
  gap: 8px;

  font-size: 12px;
  font-weight: 400;
  line-height: 14.52px;
  text-align: left;
  color: ${({ isActive }) => isActive ? "rgba(90, 120, 255, 1)" : "rgba(81, 81, 81, 1)"};


  img{
    width: 18px;
    height: 18px;
  }
`

const CreditCard = styled.div`
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & > div{
    display: flex;
    flex-direction: column;
    gap: 16px;

    button{
      width: 100%;
      height: 44px;
      border: solid 1px rgba(90, 120, 255, 1);
      color: rgba(90, 120, 255, 1);
      border-radius: 4px;
      background-color: white;
      padding-inline: 32px;

      display: flex;
      align-items: center;
      justify-content: space-between;

      margin-top: 16px;

      font-size: 16px;
      font-weight: 700;
      line-height: 20px;
      text-align: center;

    }

  }

  h3{
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    color: rgba(81, 81, 81, 1);
    letter-spacing: normal;
  }

  button{
    margin: 0;
  }
`

const CardInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;

  p{
    color: rgba(153, 153, 153, 1);
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    text-align: left;
    margin-bottom: 4px;
  }

  span{
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    color: rgba(90, 120, 255, 1);

  }
`